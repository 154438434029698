import { Grid } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as DownArrowIcon } from "../../../aseets/downArrowIcon.svg";
import SearchIcon from "@mui/icons-material/Search";
import OutsideClickHandler from "react-outside-click-handler";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Dummy from "../../../aseets/doctorAvatar.jpg";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { FlareSharp, PartyMode } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router";
import notification from "../../../components/Toast";
let timeSlots = [
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
];
const hoursConverter = (inputHr) => {
  const hours = inputHr.substring(0, 2);
  let output = "";
  if (+hours > 12) {
    output = `${+hours - 12}:${inputHr.substr(3)} PM`;
  } else {
    output = `${+hours}:${inputHr.substr(3)} AM`;
  }
  return output;
};
const BaseUrl = process.env.REACT_APP_BASE_URL;
var date = new Date();
var currentDate = date.toISOString().substring(0, 10);
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};
const style = {
  position: "absolute",
  top: "0px",
  right: "0px",
  width: 500,
  maxWidth: "100%",
  height: "100%",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  bgcolor: "#fff",
  boxShadow: 24,
};
function CreateAppointment() {
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  let [doctors, setDoctors] = useState([]);
  let [patient, setPatient] = useState([]);
  const [isDoctorOpen, setIsDoctorOpen] = useState(false);
  const [isPatientOpen, setIsPatientOpen] = useState(false);
  let [timeBox, setTimeBox] = useState(false);
  let [selectedDoctor, setSelectedDoctor] = useState({});
  let [selectedPatient, setSelectedPatient] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  let [input, setInput] = useState("");
  let [patientInput, setPatientInput] = useState("");
  let [occupiedSlots, setOccupiedSlots] = useState([]);
  const [accessibleSlots, setAccessibleSlots] = useState([]);
  let [step2, setStep2] = useState(false);
  let [formData, setFormData] = useState({
    date: currentDate,
    time: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "Male",
    occupation: "",
  });
  const handleClose = () => setOpen(false);
  let changeHandler = (e) => {
    if (
      !document.getElementById("doctorName_error").classList.contains("hidden")
    ) {
      document.getElementById("doctorName_error").classList.add("hidden");
    }
    setInput(e.target.value);
    let arr = filterByValue(e.target.value);
    console.log(arr);
    setDoctors(arr);
  };

  let patientChangeHandler = (e) => {
    if (
      !document.getElementById("patientName_error").classList.contains("hidden")
    ) {
      document.getElementById("patientName_error").classList.add("hidden");
    }
    setPatientInput(e.target.value);
    let arr = patientfilterByValue(e.target.value);
    console.log(arr);
    setPatient(arr);
  };

  const clickHandler = () => {
    let arr = filterByValue("");
    setDoctors(arr);
    setIsDoctorOpen(false);
  };

  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/users?filter[role][_eq]=e2709430-b9bc-4945-893b-e8f9b223f63a`,
        config
      )
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setDoctors(res.data.data);
      });
    console.log(new Date());
  }, []);

  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/users?filter[role][_eq]=1970af88-e4ca-49bf-bfda-b18950e5f5f2`,
        config
      )
      .then((res) => {
        console.log(res);
        setPatientData(res.data.data);
        setPatient(res.data.data);
      }).catch(() => {
        notification("error", "Something Wrong in Select Patient")
      });
    console.log(new Date());
  }, []);

  function filterByValue(value) {
    return data.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  function patientfilterByValue(value) {
    return patientData.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  // handling popup open close events
  function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const innerRef = useRef(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(e.target)
        )
          callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
  }

  const popupRef = useOuterClick((ev) => {
    if (isDoctorOpen) {
      setIsDoctorOpen(false);
    }
  });

  const popupPatientRef = useOuterClick((ev) => {
    if (isPatientOpen) {
      setIsPatientOpen(false);
    }
  });

  let changeSelectedDoctor = (item) => {
    setSelectedDoctor(item);
    // setDoctors([]);
    setIsDoctorOpen(false);
    setInput(`${item.first_name} ${item.last_name}`);
  };
  ////////////////////////////////////////////////////////////////////////////

  let changeSelectedPatient = (item) => {
    console.log("item", item)
    setSelectedPatient(item);
    // setDoctors([]);
    setFormData((prev) => {
      return {
        ...prev,
        firstName: item?.first_name ? item?.first_name : "",
        lastName: item?.last_name ? item?.last_name : "",
        dob: item?.date_of_birth ? item?.date_of_birth : "",
        gender: item?.gender ? item?.gender : "",
        occupation: item?.occupation ? item?.occupation : "",
      }
    })
    setIsPatientOpen(false);
    setPatientInput(`${item.first_name} ${item.last_name}`);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  let closeDrop = () => {
    // setDoctors([]);
    setIsDoctorOpen(false);
  };


  const [concentData, setConcentData] = useState([]);

  let dataHandler = (e) => {
    if (
      !document
        .getElementById(`${e.target.id}_error`)
        .classList.contains("hidden")
    ) {
      document.getElementById(`${e.target.id}_error`).classList.add("hidden");
    }
    if (e.target.id == "time") {
      setTimeForAppointment(e);
    } else {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });
    }
  };
  let setTimeForAppointment = async () => {
    if (!document.getElementById(`time_error`).classList.contains("hidden")) {
      document.getElementById(`time_error`).classList.add("hidden");
    }
    if (!selectedDoctor.id) {
      document.getElementById("doctorName_error").innerHTML =
        "Please select a Doctor first";
      document.getElementById("doctorName_error").classList.remove("hidden");
    } else {
      const doctor_id = selectedDoctor.id;
      const doctorSlots = await axios.get(
        `${BaseUrl}/users/${doctor_id}?fields=*,slots.slots_id.*`
      );
      console.log(
        "--------------------------- doctor slots ------------------------------"
      );
      // console.log("doctorSlots", doctorSlots);
      console.log(
        "--------------------------- doctor slots ------------------------------"
      );

      const currentDay = new Date(formData.date).getDay();
      const today = new Date().toISOString().split('T')[0];
      console.log("today", today)
      console.log("formData.date", formData.date)
      setAccessibleSlots([]);
      for (let i = 0; i < doctorSlots?.data?.data?.slots.length; i++) {
        const slot = doctorSlots?.data?.data?.slots[i].slots_id;
        if (+slot.day === currentDay) {
          let finalAccessibleSlots = [];
          for (let j = 0; j < slot?.availability?.length; j++) {
            const slotTime = slot?.availability[j];
            const slotStartTime = slotTime.split("-")[0];
            const finalStr = `${slotStartTime.substr(
              0,
              2
            )}:${slotStartTime.substr(2)}`;
            if (formData.date == today) {
              const combinedDate = new Date(`${today}T${finalStr}`);
              if (combinedDate > new Date()) {
                finalAccessibleSlots.push(finalStr);
              }
            } else {
              finalAccessibleSlots.push(finalStr);
            }
          }
          console.log(finalAccessibleSlots);
          setAccessibleSlots(finalAccessibleSlots);
          break;
        }
      }
      axios
        .get(
          `${BaseUrl}/items/Appointment?fields=start_time&filter[doctor][_eq]=${selectedDoctor.id}&filter[status][_eq]=Pending&filter[date][_eq]=${formData.date}`
        )
        .then((res) => {
          console.log(res);
          let arr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            arr.push(res.data.data[i].start_time);
          }
          console.log(arr);
          setOccupiedSlots(arr);
          setTimeBox(true);
        });
    }
  };

  let checkFormData = () => {
    let focusField = "";
    let fields = [
      "date",
      "time",
      "firstName",
      "lastName",
      "dob",
      "gender",
      "occupation",
    ];
    for (let i = 0; i < fields.length; i++) {
      if (formData[fields[i]] == "") {
        if (fields[i] === "time") {
          document.getElementById(
            `${fields[i]}_error`
          ).innerHTML = `Select ${fields[i]} Slot To Book Appointment`;
          document
            .getElementById(`${fields[i]}_error`)
            .classList.remove("hidden");
        }
        if (fields[i] === "firstName") {
          document.getElementById(
            `${fields[i]}_error`
          ).innerHTML = `First Name must be filled out`;
          document
            .getElementById(`${fields[i]}_error`)
            .classList.remove("hidden");
        }
        if (fields[i] === "lastName") {
          document.getElementById(
            `${fields[i]}_error`
          ).innerHTML = `Last Name must be filled out`;
          document
            .getElementById(`${fields[i]}_error`)
            .classList.remove("hidden");
        }
        if (fields[i] === "dob") {
          document.getElementById(
            `${fields[i]}_error`
          ).innerHTML = `Date of Birth must be selected`;
          document
            .getElementById(`${fields[i]}_error`)
            .classList.remove("hidden");
        }
        if (fields[i] === "occupation") {
          document.getElementById(
            `${fields[i]}_error`
          ).innerHTML = `Occupation must be filled out`;
          document
            .getElementById(`${fields[i]}_error`)
            .classList.remove("hidden");
        }

        if (focusField == "") {
          focusField = fields[i];
        }
      }
    }
    if (formData.firstName !== "" && formData.firstName.length > 30) {
      document.getElementById(
        `firstName_error`
      ).innerHTML = `Enter Valid First Name`;
      document.getElementById(`firstName_error`).classList.remove("hidden");
      if (focusField == "") {
        focusField = "firstName";
      }
    }
    if (formData.lastName !== "" && formData.lastName.length > 30) {
      document.getElementById(
        `lastName_error`
      ).innerHTML = `Enter Valid First Name`;
      document.getElementById(`lastName_error`).classList.remove("hidden");
      if (focusField == "") {
        focusField = "lastName";
      }
    }
    if (formData.occupation !== "" && formData.occupation.length > 30) {
      document.getElementById(
        `occupation_error`
      ).innerHTML = `Enter Valid First Name`;
      document.getElementById(`occupation_error`).classList.remove("hidden");
      if (focusField == "") {
        focusField = "occupation";
      }
    }
    if (focusField !== "") {
      document.getElementById(focusField).focus();
    } else {
      setOpen(true);
    }
  };
  let selectTime = (time) => {
    if (accessibleSlots.includes(time) && !occupiedSlots.includes(time)) {
      setFormData({
        ...formData,
        time: time,
      });
      setTimeBox(false);
    } else {

      notification(
        "warning",
        "This time slot is already booked. Please select another time slot."
      );
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  let createAppointment = () => {
    let genderArray = [];
    genderArray.push(formData.gender);
    let concentDataMod = [];
    for (let i = 0; i < concentData.length; i++) {
      const singleData = concentData[i];
      const newData = {
        answer_id: singleData,
      };
      concentDataMod.push(newData);
    }
    let body = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      date_of_birth: formData.dob,
      gender: genderArray,
      occupation: formData.occupation,
      consent_data: concentDataMod,
    };

    axios.post(`${BaseUrl}/items/userData`, body, config).then((res) => {
      console.log("success", res.data);
      if (res.data.data) {
        let appointmentBody = {
          status: "Pending",
          appointment_for: "others",
          doctor: selectedDoctor.id,
          start_time: formData.time,
          date: formData.date,
          datetime: `${formData.date}T${formData.time}:00`,
          patient_details: res.data.data.id,
        };
        setTimeout(() => {
          axios
            .post(`${BaseUrl}/items/Appointment`, appointmentBody, config)
            .then((res) => {
              console.log(res);
              navigate("/admin");
            })
            .catch((err) => {
              console.log(err);
              window.location.reload();
            });
        }, 1000);
      }
    });
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("sort", "rank");
    if (formData.gender === "Male") {
      params.append("filter[visibility_male]", "true");
    } else if (formData.gender === "Female") {
      params.append("filter[visibility_female]", "true");
    }
    axios
      .get(`${BaseUrl}/items/questions?${params}`)
      .then((res) => {
        console.log(res);
        let newData = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const singleQue = res.data.data[i];
          const newObj = {
            queData: singleQue,
            question: singleQue.id,
            answer: false,
          };
          newData.push(newObj);
        }
        setConcentData(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formData.gender]);
  const concentChangeHandler = (index, value) => {
    setConcentData((concentData) => {
      let newConcentData = concentData;
      newConcentData[index].answer = value;

      return [...newConcentData];
    });
  };
  const clearFields = () => {
    setFormData({
      date: currentDate,
      time: "",
      firstName: "",
      lastName: "",
      dob: "",
      gender: "Male",
      occupation: "",
    });
  };
  //  console.log("lkdjkj", patient)
  // console.log("selectedPatient", selectedPatient)
  return (
    <div className="mt-[1rem] mb-[2rem]">
      <Grid container spacing={8}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div>
            <div className="text-[22px] font-bold mb-[2rem]">
              New Appointment
            </div>
            <div className="text-[18px] font-semibold mb-2">
              Selected Doctor
            </div>
            <div ref={popupRef} className="relative mb-8">
              <SearchIcon className="absolute top-[10px] left-1" />
              <ExpandMoreIcon
                sx={{ padding: "2px", fontSize: "2rem", color: "white" }}
                className="absolute right-1 top-[7px] bg-primary rounded-lg cursor-pointer"
                onClick={() => document.getElementById("doctorInput").focus()}
              />
              <input
                type="text"
                className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-lg"
                placeholder="Search Doctors..."
                value={input}
                id="doctorInput"
                onChange={changeHandler}
                onFocus={() => setIsDoctorOpen(true)}
              />
              <div
                className="mt-1 text-red-400 hidden"
                id="doctorName_error"
              ></div>
              <div>
                {doctors.length > 0 && isDoctorOpen && (
                  <div className="absolute top-[50px] z-10 w-[100%] bg-white border border-gray-400 rounded-md max-h-[200px] overflow-y-auto">
                    {doctors.map((item, i) => {
                      return (
                        <div
                          className="flex items-center p-2 hover:bg-[#e5e5e5] cursor-pointer"
                          onClick={(e) => changeSelectedDoctor(item)}
                        >
                          <img
                            className="w-[40px] h-[40px] rounded-md mr-1 font-semibold"
                            src={Dummy}
                          />
                          <div className="capitalize">
                            Dr.{item.first_name} {item.last_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="text-[18px] font-semibold mb-2">Date & Time</div>
            <div className="flex mb-7">
              <div className="w-[50%]">
                <input
                  type="date"
                  min={new Date().toISOString().split('T')[0]}
                  className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-l-lg border-r-2 border-gray-400"
                  value={formData.date}
                  id="date"
                  onChange={dataHandler}
                />
              </div>
              <div className="w-[50%]" onClick={setTimeForAppointment}>
                <input
                  className="w-[100%] bg-[#F4F5F9] pt-[10px] pb-[12px]  px-[35px] rounded-r-lg"
                  value={formData.time}
                  placeholder="Select time slot"
                  id="time"
                />
              </div>
            </div>
            {/* ///////////////////////////////////////////////////// */}
            <div className="text-[18px] font-semibold mb-2">
              Selected Patient
            </div>
            <div ref={popupPatientRef} className="relative mb-8">
              <SearchIcon className="absolute top-[10px] left-1" />
              <ExpandMoreIcon
                sx={{ padding: "2px", fontSize: "2rem", color: "white" }}
                className="absolute right-1 top-[7px] bg-primary rounded-lg cursor-pointer"
                onClick={() => document.getElementById("patientInput").focus()}
              />
              <input
                type="text"
                className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-lg"
                placeholder="Search Patient..."
                value={patientInput}
                id="patientInput"
                onChange={patientChangeHandler}
                onFocus={() => setIsPatientOpen(true)}
              />
              <div
                className="mt-1 text-red-400 hidden"
                id="patientName_error"
              ></div>
              <div>
                {patient.length > 0 && isPatientOpen && (
                  <div className="absolute top-[50px] z-10 w-[100%] bg-white border border-gray-400 rounded-md max-h-[200px] overflow-y-auto">
                    {patient.map((item, i) => {
                      return (
                        <div
                          className="flex items-center p-2 hover:bg-[#e5e5e5] cursor-pointer"
                          onClick={(e) => changeSelectedPatient(item)}
                        >
                          <img
                            className="w-[40px] h-[40px] rounded-md mr-1 font-semibold"
                            src={Dummy}
                          />
                          <div className="capitalize">
                            {item.first_name} {item.last_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {/* ///////////////////////////////////////////////////// */}

            <div className="mt-1 text-red-400 hidden" id="date_error"></div>
            <div className="mt-1 text-red-400 hidden" id="time_error"></div>
            <div className="text-[16px] text-[#AFAFAF] font-bold mb-1">
              First Name
            </div>
            <input
              type="text"
              className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-lg mb-4"
              placeholder="Enter First Name"
              value={formData.firstName}
              id="firstName"
              onChange={dataHandler}
            />
            <div
              className="mt-1 text-red-400 hidden"
              id="firstName_error"
            ></div>
            <div className="text-[16px] text-[#AFAFAF] font-bold mb-1">
              Last Name
            </div>
            <input
              type="text"
              className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-lg mb-4"
              placeholder="Enter Last Name"
              value={formData.lastName}
              id="lastName"
              onChange={dataHandler}
            />
            <div className="mt-1 text-red-400 hidden" id="lastName_error"></div>
            <Grid container spacing={4}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="text-[16px] text-[#AFAFAF] font-bold mb-1">
                  Date of Birth
                </div>
                <input
                  type="date"
                  className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-lg mb-4"
                  placeholder="Enter First Name"
                  max={currentDate}
                  value={formData.dob}
                  id="dob"
                  onChange={dataHandler}
                />
                <div className="mt-1 text-red-400 hidden" id="dob_error"></div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="text-[16px] text-[#AFAFAF] font-bold mb-1">
                  Gender
                </div>
                <select
                  className="w-[100%] bg-[#F4F5F9] py-[12px] px-[35px] rounded-lg mb-4"
                  value={formData.gender}
                  id="gender"
                  onChange={dataHandler}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                <div
                  className="mt-1 text-red-400 hidden"
                  id="gender_error"
                ></div>
              </Grid>
            </Grid>
            <div className="text-[16px] text-[#AFAFAF] font-bold mb-1">
              Occupation
            </div>
            <input
              type="text"
              className="w-[100%] bg-[#F4F5F9] py-[10px] px-[35px] rounded-lg mb-4"
              placeholder="Enter Occupation"
              value={formData.occupation}
              id="occupation"
              onChange={dataHandler}
            />
            <div
              className="mt-1 text-red-400 hidden"
              id="occupation_error"
            ></div>
          </div>
        </Grid>
        {step2 && (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div>
              <div className="flex justify-between items-center mb-[1rem]">
                <div className="text-[22px] font-bold">Self Declaration</div>
                <button
                  className="w-fit text-white bg-primary px-[30px] py-[10px] mt-4 rounded-lg font-bold"
                  onClick={checkFormData}
                >
                  Edit
                </button>
              </div>
              <div className="bg-[#F4F5F9] p-[1rem] lg:p-[2rem] xl:p-[2rem] rounded-xl">
                {concentData.map((que, index) => {
                  return (
                    <div key={que.question} className="mb-8">
                      <div className="text-white bg-primary float-right w-[5rem] text-center px-[20px] py-[5px] rounded-lg">
                        {que.answer ? "Yes" : "No"}
                      </div>
                      <div className="text-[#243448]">
                        {index + 1}. {que.queData.Question}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {step2 ? (
        <button
          className="w-fit text-white bg-primary px-[30px] py-[10px] mt-4 rounded-lg font-bold"
          onClick={createAppointment}
        >
          Create Appointment
        </button>
      ) : (
        <div className="flex gap-4 flex-wrap">
          <button
            className="w-fit text-white bg-primary px-[30px] py-[10px] mt-4 rounded-lg font-bold"
            onClick={checkFormData}
          >
            Next
          </button>
          <button
            className="w-fit  text-primary bg-[#F4F5F9] px-[30px] py-[10px] mt-4 rounded-lg font-bold"
            onClick={clearFields}
          >
            Clear
          </button>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="focus-visible: outline-none h-full overflow-y-auto"
        >
          <div className="flex items-center mb-6 ">
            <CloseIcon
              onClick={() => handleClose()}
              style={{ padding: "7px", fontSize: "2.5rem", color: "#fff" }}
              className="bg-primary rounded-lg cursor-pointer mr-4 "
            />
            <div className="font-bold text-[1.4rem]">Self Declaration</div>
          </div>
          <div className="font-bold text-[1.2rem] mb-4">Self Declaration</div>
          <div className="font-light text-[#333333] mb-8">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.In felis,
            volutpat magna dictum in.
          </div>
          {concentData.map((que, index) => {
            return (
              <>
                <div key={que.id} className="text-[#243448] mb-3">
                  {index + 1}. {que.queData.Question}
                </div>
                <div
                  onClick={() => concentChangeHandler(index, !que.answer)}
                  className="w-[100%] max-w-[350px] border border-gray-300 rounded-lg p-2 mb-4"
                >
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <div
                        className={`w-[100%] py-[7px] ${que.answer
                          ? "bg-primary text-white"
                          : "bg-white text-[#E0E0E0]"
                          }  text-center rounded-md cursor-pointer`}
                      >
                        Yes
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <div
                        className={`w-[100%] py-[7px] ${!que.answer
                          ? "bg-primary text-white"
                          : "bg-white text-[#E0E0E0]"
                          }  text-center rounded-md cursor-pointer`}
                      >
                        No
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </>
            );
          })}

          <button
            className="w-full text-center py-[1rem] font-bold text-[20px] text-white bg-primary rounded-lg"
            onClick={() => (setStep2(true), setOpen(false))}
          >
            Save Details
          </button>
        </Box>
      </Modal>
      <Modal
        open={timeBox}
        onClose={() => setTimeBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="focus-visible:outline-none rounded-2xl">
          <div className="bg-primary text-white font-bold text-[20px] text-center pt-[15px] pb-[30px] rounded-t-2xl">
            Choose Time
          </div>
          <div className="bg-[#e5e5e5] p-[10px] rounded-b-2xl">
            <div>
              <Grid container spacing={2}>
                {timeSlots.map((item, i) => {
                  return (
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <div
                        className={`w-[100%] py-[10px] rounded-md text-center ${accessibleSlots.includes(item) &&
                          !occupiedSlots.includes(item)
                          ? "bg-white cursor-pointer"
                          : "bg-[rgba(50,47,47,0.97)] text-white  cursor-not-allowed"
                          }
                       `}
                        onClick={() => selectTime(item)}
                      >
                        {hoursConverter(item)}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CreateAppointment;
