import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

let refresh = false;
axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    let originalConfig = error.config
    if (
      localStorage.getItem("access_token") &&
      error.response.status === 401 &&
      !refresh
    ) {
      refresh = true;
      // alert("waiting for refresh");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("refresh_token"),
        }),
      };
      const response = await fetch(`${baseURL}/auth/refresh`, requestOptions);
      const data = await response.json();
      if (data.data) {
        localStorage.setItem("access_token", data.data.access_token);
        localStorage.setItem("refresh_token", data.data.refresh_token);
      } else {
        //logout
      }
      console.log(data);
      if (data.data) {
        originalConfig = {
          ...originalConfig,
          headers: {
            ...originalConfig.headers,
            Authorization: `Bearer ${data.data.access_token}`,
          },
        };
        return axios(originalConfig);
      }
    }
    refresh = false;
    return Promise.reject(error);
  }
);