import DoctorProfileBg from "../../../../aseets/doctorProfileBg.png";
import DummyUser from "../../../../aseets/dummyUser.png";
import { ReactComponent as LanguageIcon } from "../../../../aseets/languageIcon.svg";
import { ReactComponent as SecurityIcon } from "../../../../aseets/securityIcon.svg";
import { ReactComponent as HelpAndSupportIcon } from "../../../../aseets/helpAndSupportIcon.svg";
import { ReactComponent as ContactUsIcon } from "../../../../aseets/contactUsIcon.svg";
const DoctorProfile = () => {
  return (
    <div>
      <div className="relative pb-[4rem]">
        <div className="h-[10rem] bg-[#2EB9AC99]">
          <img
            className="w-[100%] h-[100%] object-cover mix-blend-soft-light"
            src={DoctorProfileBg}
            alt="..."
          />
        </div>
        <img
          className="w-[7rem] h-[7rem] border-[5px] border-white rounded-[50%] absolute bottom-[.5rem] left-[50%] translate-x-[-50%]"
          src={DummyUser}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <p className="text-[1.4rem] text-black font-medium">Akshita Patel</p>
        <p className="text-[1rem] text-[#959595] font-medium">@akshita</p>
      </div>

      <div className="mt-[2rem]">
        <div className="w-[100%] bg-[#F4F5F9] text-textColor py-[1rem] px-[1.9rem] mb-[1rem] flex rounded-[.5rem]">
          <LanguageIcon className="w-[1.3rem] h-[1.3rem] mr-[1rem]" />
          <p>Language</p>
        </div>
        <div className="w-[100%] bg-[#F4F5F9] text-textColor py-[1rem] px-[1.9rem] mb-[1rem] flex rounded-[.5rem]">
          <SecurityIcon className="w-[1.3rem] h-[1.3rem] mr-[1rem]" />
          <p>Security</p>
        </div>
        <div className="w-[100%] bg-[#F4F5F9] text-textColor py-[1rem] px-[1.9rem] mb-[1rem] flex rounded-[.5rem]">
          <HelpAndSupportIcon className="w-[1.3rem] h-[1.3rem] mr-[1rem]" />
          <p>Help And Support</p>
        </div>
        <div className="w-[100%] bg-[#F4F5F9] text-textColor py-[1rem] px-[1.9rem] mb-[1rem] flex rounded-[.5rem]">
          <ContactUsIcon className="w-[1.3rem] h-[1.3rem] mr-[1rem]" />
          <p>Contact Us</p>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
