import React, { useState } from "react";
import { ReactComponent as LoactionIcon } from "../../aseets/locationIcon.svg";
import DummyUser from "../../aseets/patientAvatar.jpg";
import { ReactComponent as CalendarIcon } from "../../aseets/calendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../aseets/clockIcon.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, Grid } from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { ReactComponent as BackIcon } from "../../aseets/backIcon.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import notification from "../Toast";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};
var date = new Date();
var currentDate = date.toISOString().substring(0, 10);
let timeSlots = [
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
];
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "100%",
  bgcolor: "#fff",
  boxShadow: 24,
};

let months = [
  "",
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const hoursConverter = (inputHr) => {
  const hours = inputHr.substring(0, 2);
  let output = "";
  if (+hours > 12) {
    output = `${+hours - 12}:${inputHr.substr(3)} PM`;
  } else {
    output = `${+hours}:${inputHr.substr(3)} AM`;
  }
  return output;
};
const AppointmentCard = ({
  data,
  onReschedulePress = () => {},
  reloadData = () => {},
}) => {
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  let [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
  let [selectedDate, setSelectedDate] = useState(currentDate);
  const [timeBox, setTimeBox] = useState(false);
  let [date, setDate] = useState(new Date());
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [selectedTime, setSelectedTime] = useState("");
  let getAge = (dob = "2012-04-12") => {
    var year = Number(dob.substr(0, 4));
    var month = Number(dob.substr(5, 2)) - 1;
    var day = Number(dob.substr(7, 2));
    var today = new Date();
    var age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() == month && today.getDate() < day)
    ) {
      age--;
    }
    return age;
  };
  let getTime = (time = "15:00") => {
    let smallTime = time.slice(0, 2);
    if (smallTime <= 12) {
      return `${smallTime}:${time.slice(3, 5)} AM`;
    } else {
      smallTime = smallTime - 12;
      return `${smallTime < 10 && "0"}${smallTime}:${time.slice(3, 5)} PM`;
    }
  };

  let showAppointment = (id) => {
    if (
      localStorage.getItem("role") == "e2709430-b9bc-4945-893b-e8f9b223f63a"
    ) {
      navigate(`/appointment/${id}`);
    }
  };
  let setAppointmentCancel = () => {
    let body = {
      status: "Cancelled",
      cancel_reason:
        cancelReason ??
        `Cancelled by ${
          localStorage.getItem("role") == "6e244d53-82c5-4aa2-8d90-9e737f8da3ca"
            ? "patient"
            : "doctor"
        }`,
      canceled_by:
        localStorage.getItem("role") == "6e244d53-82c5-4aa2-8d90-9e737f8da3ca"
          ? "patient"
          : "doctor",
    };
    axios
      .patch(`${BaseUrl}/items/Appointment/${data.id}`, body, config)
      .then((res) => {
        setOpen(false);
        reloadData();
        setCancelReason("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let [cancelReason, setCancelReason] = useState("");
  let [occupiedSlots, setOccupiedSlots] = useState([]);
  const [accessibleSlots, setAccessibleSlots] = useState([]);
  useEffect(() => {
    if (timeBox) {
      const updatingSlots = async () => {
        const doctor_id = data.doctor.id;
        const doctorSlots = await axios.get(
          `${BaseUrl}/users/${doctor_id}?fields=*,slots.slots_id.*`
        );
        const currentDay = new Date(selectedDate).getDay();
        setAccessibleSlots([]);
        for (let i = 0; i < doctorSlots?.data?.data?.slots.length; i++) {
          const slot = doctorSlots?.data?.data?.slots[i].slots_id;
          if (+slot.day === currentDay) {
            let finalAccessibleSlots = [];
            for (let j = 0; j < slot?.availability?.length; j++) {
              const slotTime = slot?.availability[j];
              const slotStartTime = slotTime.split("-")[0];
              const finalStr = `${slotStartTime.substr(
                0,
                2
              )}:${slotStartTime.substr(2)}`;
              finalAccessibleSlots.push(finalStr);
            }
            setAccessibleSlots(finalAccessibleSlots);
            break;
          }
        }
        axios
          .get(
            `${BaseUrl}/items/Appointment?fields=start_time&filter[doctor][_eq]=${data.doctor.id}&filter[status][_eq]=Pending&filter[date][_eq]=${selectedDate}`
          )
          .then((res) => {
            let arr = [];
            for (let i = 0; i < res.data.data.length; i++) {
              arr.push(res.data.data[i].start_time);
            }
            setOccupiedSlots(arr);
          });
      };
      updatingSlots();
    }
  }, [selectedDate, timeBox]);
  let selectTimeThis = (item) => {
    if (accessibleSlots.includes(item) && !occupiedSlots.includes(item)) {
      setSelectedTime(item);
    } else {
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      notification(
        "warning",
        "This time slot is already booked. Please select another time slot."
      );
    }
  };
  let rescheduleAppoint = () => {
    let body = {
      status: "Pending",
      start_time: selectedTime,
      date: selectedDate,
      datetime: `${selectedDate}T${selectedTime}:00`,
    };
    axios
      .patch(`${BaseUrl}/items/Appointment/${data.id}`, body, config)
      .then((res) => {
        setTimeBox(false);
        reloadData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let navToReport = (id) => {
    if (
      localStorage.getItem("role") == "e2709430-b9bc-4945-893b-e8f9b223f63a"
    ) {
      navigate(`/report/${id}`);
    } else {
      navigate(`/admin/report/${id}`);
    }
  };
  let changeHandler = (e) => {
    setCancelReason(e.target.value);
  };
  // set status color
  const setStatusBgColor = (value) => {
    if (value === "Completed") {
      return "bg-green-500";
    } else if (value === "Pending") {
      return "bg-orange-500";
    } else if (value === "Cancelled") {
      return "bg-red-500";
    }
  };
  return (
    <div className="p-[5rem] shadow-appointmentCardShadow rounded-[.7rem] px-[1.5rem] py-[.8rem] min-w-[100%] h-full">
      <div
        className="py-[1rem] border-b-[1px] border-b-[#F2F3F6] flex justify-between"
        onClick={() => showAppointment(data.id)}
      >
        <div className="w-[100%] pr-[.5rem] h-[4rem]">
          <p className="text-textColor font-semibold text-[1.2rem]">
            {data.appointment_for == "myself"
              ? `${data.user_created.first_name} ${data.user_created.last_name}`
              : `${data.patient_details.first_name} ${data.patient_details.last_name}`}
          </p>
          <p className="flex text-textColor text-[.9rem] mt-[.2rem] justify-start items-center flex-wrap">
            {data.appointment_for == "myself"
              ? data.user_created.gender
              : data.patient_details.gender}
            <div className="w-[.4rem] h-[.4rem] bg-[#65707E] rounded-[50%] mx-[.3rem]"></div>
            {data.appointment_for && data.appointment_for == "myself"
              ? data.user_created.date_of_birth &&
                getAge(data.user_created.date_of_birth)
              : data.patient_details.date_of_birth &&
                getAge(data.patient_details.date_of_birth)}{" "}
            Years
            <div className="w-[.4rem] h-[.4rem] bg-[#65707E] rounded-[50%] mx-[.3rem]"></div>
            {data.appointment_for == "myself"
              ? data.user_created.occupation
              : data.patient_details.occupation}
          </p>
          {/* <div className="mt-[.5rem] flex items-center">
            <LoactionIcon className="w-[1.3rem] h-[1.3rem] text-[#C2C7D1] mr-[.5rem]" />
            <p className="text-[.8rem] mt-[.2rem] text-textColor">
              Central City memorial hospital
            </p>
          </div> */}
        </div>
        <img
          className="w-[5rem] h-[5rem] rounded-[1rem] object-cover"
          src={
            data.appointment_for == "myself"
              ? data.user_created.avatar
                ? `${BaseUrl}/assets/${data.user_created.avatar}`
                : DummyUser
              : data.patient_details.avatar
              ? `${BaseUrl}/assets/${data.patient_details.avatar}`
              : DummyUser
          }
          alt="..."
        />
      </div>
      <div>
        <div className="flex justify-between py-[.5rem] ">
          <div className="flex items-center">
            <CalendarIcon className="w-[1.2rem] h-[1.2rem] text-[#A8AFBD] mr-[.5rem]" />
            <p className="text-[.9rem] mt-[.15rem] text-textColor">
              {`${data.date.slice(8, 10)}-${data.date.slice(
                5,
                7
              )}-${data.date.slice(0, 4)}`}
            </p>
          </div>
          <div className="flex items-center">
            <ClockIcon className="w-[1.2rem] h-[1.2rem] text-[#A8AFBD] mr-[.5rem]" />
            <p className="text-[.9rem] mt-[.15rem] text-textColor">
              {getTime(data.start_time)}
            </p>
          </div>
          <div className="flex items-center">
            <div
              className={`w-[.5rem] h-[.5rem]   ${setStatusBgColor(
                data?.status
              )} rounded-[50%] mr-[.5rem]`}
            ></div>
            <p className="text-[.9rem] mt-[.15rem] text-textColor">
              {data.status}
            </p>
          </div>
        </div>
        {data?.status === "Cancelled" && data?.cancel_reason && (
          <div className="my-2 w-full">
            <Alert severity="error">{data?.cancel_reason}</Alert>
          </div>
        )}
        <div className="flex justify-between items-center gap-[1rem] mt-[.5rem]">
          {data.status === "Pending" && (
            <button
              className="bg-[#F4F5F9] w-[100%] py-[.7rem] rounded-[.3rem] text-[#474747] font-medium"
              onClick={() => setOpen(true)}
            >
              Cancel
            </button>
          )}
          {data.status === "Completed" && (
            <button
              className="bg-primary w-[100%] py-[.7rem] rounded-[.3rem]font-medium text-white"
              onClick={() => navToReport(data.Reports.id)}
            >
              View Report
            </button>
          )}
          {data.status === "Pending" && (
            <button
              onClick={() => setTimeBox(true)}
              className="bg-primary w-[100%] py-[.7rem] rounded-[.3rem] text-white font-medium"
              disabled={data.status === "Completed"}
            >
              Reschedule
            </button>
          )}
          {data.status === "Cancelled" && (
            <div
              className="bg-primary w-[100%] py-[.7rem] rounded-[.3rem] text-center text-white font-medium"
              disabled={data.status === "Completed"}
              // onClick={() => navToReport(data.Reports.id)}
            >
              View Report
            </div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center font-bold text-[1.4rem] mb-2">
            Cancel Appointment
          </div>
          {/* <div className="font-normal text-center mb-[2rem]">
            Here&apos;s an alert description that uses Auto Layout!
          </div> */}

          <div className="mb-[2rem]">
            {/* <p className="text-[1.35rem] mb-[.3rem] font-medium">
              Cancel Reason
            </p> */}
            <textarea
              className="border-[1px] h-[8rem] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
              placeholder="Cancel Reason"
              value={cancelReason}
              onChange={changeHandler}
              id="transaction_details"
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <button
                className="w-full text-center py-[15px] bg-[#e5e5e5] rounded-lg"
                onClick={() => setOpen(false)}
              >
                No
              </button>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <button
                className="w-full text-center py-[15px] bg-[#EB5757] text-white rounded-lg"
                onClick={() => {
                  setAppointmentCancel("ASd");
                }}
              >
                Yes, Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={timeBox}
        onClose={() => setTimeBox(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1} className="focus-visible:outline-none rounded-2xl">
          <div className="bg-primary text-white font-bold text-[20px] text-center pt-[15px] pb-[10px] rounded-t-2xl">
            Choose Date & time
          </div>
          <div className="bg-[#e5e5e5] p-[10px] rounded-b-2xl">
            <div className="font-bold text-[16px] text-center mb-2">
              Choose Date
            </div>
            <div>
              <input
                type={"date"}
                className="mb-2 w-full p-2 rounded-xl"
                value={selectedDate}
                min={currentDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            <div className="font-bold text-[16px] text-center mb-2 mt-2">
              Choose Time
            </div>
            <div>
              <Grid container spacing={2}>
                {timeSlots.map((item, i) => {
                  return (
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <div
                        className={`w-[100%] py-[10px] rounded-md text-center ${
                          accessibleSlots.includes(item) &&
                          !occupiedSlots.includes(item)
                            ? "bg-white cursor-pointer"
                            : "bg-[rgba(50,47,47,0.97)] text-white cursor-not-allowed"
                        }
                        ${
                          selectedTime == item
                            ? "border-2 border-blue-400"
                            : "border-2 border-white"
                        } `}
                        onClick={() => selectTimeThis(item)}
                      >
                        {hoursConverter(item)}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <div
              className={`w-full py-[10px] rounded-lg text-center font-bold bg-primary ${
                selectedTime == "" ? "opacity-50" : ""
              } text-white mt-4 cursor-pointer`}
              onClick={rescheduleAppoint}
            >
              Reschedule
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AppointmentCard;
