import React from "react";

export default function TableItem({ data }) {
  console.log(data?.Reports?.Bill);
  // const patient_name = data?.patient_name[0]?.directus_users_id;
  // const patient_id = data?.patient_name[0]?.directus_users_id?.id;
  const sum = data?.Reports?.Bill?.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue.price),
    0
  );
  return (
    <tr className="text-sm text-black even:bg-[#f9f9f9] odd:bg-[#f2f2f2]">
      <td className="border-r border-l border-black text-center px-4 py-2">
        {data?.Reports?.date_created?.split(".")[0].split("T").join(" ")}
      </td>
      <td className="border-r border-l border-black text-center px-4 py-2 capitalize">
        {data.patient_details?.first_name} {data.patient_details?.last_name}
      </td>
      <td className="border-r border-l border-black text-center px-4 py-2 capitalize">
        {data?.status ? data?.status : "---"}
      </td>
      <td className="border-r border-l border-black text-center px-4 py-2">
        {data.Reports?.transaction_details ?? ""}
      </td>
      <td className="border-r border-l uppercase border-black text-center px-4 py-2">
        {data.Reports?.payment_mode ?? "cash"}
      </td>
      <td className="border-r border-l border-black text-center px-4 py-2">
        {typeof sum == "number" ? sum : 0}
      </td>
    </tr>
  );
}
