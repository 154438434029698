import { ReactComponent as SearchIcon } from "../../../../aseets/searchIcon.svg";
import DummyUser from "../../../../aseets/dummyUser.png";
const Payment = () => {
  return (
    <div>
      <div className="flex justify-between items-stretch">
        <div className="flex justify-start gap-[1rem]">
          <button className="bg-primary w-[10rem] py-[.8rem] rounded-[.5rem] text-white">
            Received
          </button>
          <button className="bg-[#F4F5F9] w-[10rem] py-[.8rem] rounded-[.5rem] text-black">
            Pending
          </button>
        </div>
        <div className="relative bg-[#F4F5F9] px-[1rem] w-[35%] flex rounded-[.5rem] items-center">
          <SearchIcon className="w-[1.5rem] h-[1.5rem] text-[#797287]" />
          <input className="w-[100%] h-[100%] bg-[#F4F5F9] outline-none px-[1rem]" />
        </div>
      </div>
      <div className="mt-[2rem]">
        <div className="w-[100%] bg-white rounded-[.5rem] mb-[.8rem] shadow-md py-[.9rem] px-[1.5rem] flex justify-between items-stretch">
          <div className="w-[50%] flex items-center">
            <img
              className="w-[4rem] h-[4rem] rounded-[50%]"
              src={DummyUser}
              alt="..."
            />
            <div className="ml-[1rem]">
              <p className="text-textColor font-semibold text-[1.1rem]">
                Bruce Wayne
              </p>
              <p className="text-[#00000080]">1 day ago</p>
            </div>
          </div>
          <div className="w-[40%] flex items-stretch justify-between">
            <div className="flex items-center">
              <p className="text-primary font-medium">₹211.90</p>
            </div>
            <button className="bg-[#F4F5F9] text-primary w-[50%] rounded-[.5rem]">
              Downlaod E-reciept
            </button>
          </div>
        </div>
        <div className="w-[100%] bg-white rounded-[.5rem] mb-[.8rem] shadow-md py-[.9rem] px-[1.5rem] flex justify-between items-stretch">
          <div className="w-[50%] flex items-center">
            <img
              className="w-[4rem] h-[4rem] rounded-[50%]"
              src={DummyUser}
              alt="..."
            />
            <div className="ml-[1rem]">
              <p className="text-textColor font-semibold text-[1.1rem]">
                Bruce Wayne
              </p>
              <p className="text-[#00000080]">1 day ago</p>
            </div>
          </div>
          <div className="w-[40%] flex items-stretch justify-between">
            <div className="flex items-center">
              <p className="text-primary font-medium">₹211.90</p>
            </div>
            <button className="bg-[#F4F5F9] text-primary w-[50%] rounded-[.5rem]">
              Downlaod E-reciept
            </button>
          </div>
        </div>
        <div className="w-[100%] bg-white rounded-[.5rem] mb-[.8rem] shadow-md py-[.9rem] px-[1.5rem] flex justify-between items-stretch">
          <div className="w-[50%] flex items-center">
            <img
              className="w-[4rem] h-[4rem] rounded-[50%]"
              src={DummyUser}
              alt="..."
            />
            <div className="ml-[1rem]">
              <p className="text-textColor font-semibold text-[1.1rem]">
                Bruce Wayne
              </p>
              <p className="text-[#00000080]">1 day ago</p>
            </div>
          </div>
          <div className="w-[40%] flex items-stretch justify-between">
            <div className="flex items-center">
              <p className="text-primary font-medium">₹211.90</p>
            </div>
            <button className="bg-[#F4F5F9] text-primary w-[50%] rounded-[.5rem]">
              Downlaod E-reciept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
