import { useEffect, useState } from "react";
import axios from "axios";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const PrivacyPolicy = () => {
    let [privacyPolicyData, setPrivacyPolicyData] = useState("");
    useEffect(() => {
        axios
            .get(`${BaseUrl}/items/privacy_policy`)
            .then((res) => {
                console.log(res);
                setPrivacyPolicyData(res.data.data.body);
            });
    }, []);
    return (
        <div className="max-w-6xl mx-auto p-6">
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
            <div
                className="text-base leading-relaxed"
                dangerouslySetInnerHTML={{ __html: privacyPolicyData }}
            />
        </div>
    );
};

export default PrivacyPolicy;
