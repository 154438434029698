import DummyUser from "../../../../aseets/patientAvatar.jpg";
import { ReactComponent as CalendarIcon } from "../../../../aseets/calendarIcon.svg";
import { ReactComponent as ClockIcon } from "../../../../aseets/clockIcon.svg";
import { ReactComponent as UploadIcon } from "../../../../aseets/uploadIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../aseets/crossIcon.svg";
import { ReactComponent as PlusIcon } from "../../../../aseets/plusIcon.svg";
import { ReactComponent as DocumentUploadIcon } from "../../../../aseets/documentUploadIcon.svg";
import { useEffect, useState } from "react";
import ColorTags from "../../../../components/ColorTags";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import EditIcon from "@mui/icons-material/Edit";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import ReportThumbnail from "../../../../components/ReportThumbnail";
const BaseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};
const UserProfile = () => {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  let [data, setData] = useState({});
  let param = useParams();
  let getTime = (time = "15:00") => {
    let smallTime = time.slice(0, 2);
    if (smallTime <= 12) {
      return `${smallTime}:${time.slice(3, 5)} AM`;
    } else {
      smallTime = smallTime - 12;
      return `${smallTime < 10 && "0"}${smallTime}:${time.slice(3, 5)} PM`;
    }
  };
  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/items/Appointment?fields=*,user_created.*,doctor.*,patient_details.consent_data.*,patient_details.consent_data.answer_id.*,patient_details.consent_data.answer_id.question.*,patient_details.*&filter[id][_eq]=${param.id}`
      )
      .then((res) => {
        if (res.data) {
          console.log("res", res?.data?.data[0]);
          setData(res.data.data[0]);
        } else {
          setData({});
          navigate("/404");
        }
      });
  }, []);
  let [reportData, setReportData] = useState({
    name: "",
    description: "",
    prescription: "",
    medicalCharges: "",
    label: "",
  });
  let changeHandler = (e) => {
    setReportData({
      ...reportData,
      [e.target.id]: e.target.value,
    });
  };
  let [diagrams, setDiagrms] = useState([]);
  let addDiagram = (e) => {
    if (e.target.files[0]) {
      setDiagrms([...diagrams, e.target.files[0]]);
    }
  };
  let removeDoc = (i) => {
    let arr = diagrams.filter(function (item) {
      return item !== i;
    });
    setDiagrms([...arr]);
  };
  let [attachments, setAttachments] = useState([]);
  let addAttachment = (e) => {
    if (e.target.files[0]) {
      setAttachments([...attachments, e.target.files[0]]);
    }
  };
  let removeAtt = (i) => {
    let arr = attachments.filter(function (item) {
      return item !== i;
    });
    setAttachments([...arr]);
  };
  let [payment, setPayment] = useState({
    item: "",
    price: "",
  });
  let rateHandler = (e) => {
    setPayment({
      ...payment,
      [e.target.id]: e.target.value,
    });
  };
  let [rates, setRates] = useState([]);
  let addRate = () => {
    if (payment.item !== "" && payment.price !== "") {
      let temp = {
        item: payment.item,
        price: payment.price,
      };
      setRates([...rates, temp]);
      setPayment({
        item: "",
        price: "",
      });
    }
  };
  let removeRate = (i) => {
    let arr = rates.filter(function (item) {
      return item !== i;
    });
    setRates([...arr]);
  };
  let [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [tagsModel, setTagsModel] = useState(false);
  const tagsModelClose = () => {
    setTagsModel(false);
  };
  const changeLabel = (id) => {
    setReportData({
      ...reportData,
      label: id,
    });
    setTagsModel(false);
  };
  let uploadReport = () => {
    let arr = [];
    let arr1 = [];
    if (attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        const formData = new FormData();
        formData.append("title", attachments[i].name);
        formData.append("file", attachments[i]);
        axios.post(`${BaseUrl}/files`, formData, config).then((res) => {
          console.log(res);
          let data1 = {};
          data1.directus_files_id = res.data.data.id;
          arr1.push(data1);
        });
      }
    }
    let body = {
      name: reportData.name,
      description: reportData.description,
      medical_prescription: reportData.prescription,
      medical_charges: reportData.medicalCharges,
      attachments: arr1,
      Bill: rates,
      status: "published",
      doctor_name: [{ directus_users_id: data.doctor.id }],
      patient_name: [{ directus_users_id: data.user_created.id }],
      label: reportData.label.id,
    };
    console.log(body);
    setTimeout(() => {
      axios.post(`${BaseUrl}/items/Reports`, body, config).then((res) => {
        console.log(res);
        let body1 = {
          status: "Completed",
          Reports: res.data.data.id,
        };
        axios
          .patch(`${BaseUrl}/items/Appointment/${data.id}`, body1, config)
          .then((res1) => {
            console.log(res1);
            // navigate("/")
          });
      });
    }, 1000);
  };
  let sendPage = (id) => {
    navigate(`/report/${id}`);
  };
  return (
    <div>
      {data.appointment_for ? (
        <>
          <ReactSlidingPane
            overlayClassName={style.overlayClass}
            isOpen={isOpen}
            width="600px"
            onRequestClose={() => {
              // triggered on "<" on left top click or on outside click
              setIsOpen(false);
            }}
            hideHeader={true}
          >
            <div className="w-[100%]">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <button
                    onClick={() => setIsOpen(false)}
                    className="p-[1.2rem] bg-[#F4F5F9] rounded-[.8rem]"
                  >
                    <CrossIcon className="w-[1.1rem] h-[1.1rem] text-primary" />
                  </button>
                  <p className="font-medium text-[1.3rem] ml-[1rem]">
                    Create Report
                  </p>
                </div>
                <button
                  className="bg-primary text-white py-[.7rem] px-[2.5rem] rounded-[.5rem]"
                  onClick={uploadReport}
                >
                  Save
                </button>
              </div>
              <div className="flex items-center mt-[3rem] mb-[2rem]">
                <div className="w-[25%]">
                  <img
                    className="w-[100%] h-[100%] object-contain flex justify-center items-center"
                    src={
                      data.appointment_for == "myself"
                        ? data.user_created.avatar
                          ? `${BaseUrl}/assets/${data.user_created.avatar}`
                          : DummyUser
                        : data.patient_details.avatar
                        ? `${BaseUrl}/assets/${data.patient_details.avatar}`
                        : DummyUser
                    }
                    alt="..."
                  />
                </div>
                <div className="w-[55%] ml-[3rem]">
                  <div></div>
                  <div className="w-[100%] flex gap-[.5rem] pb-[.5rem]">
                    <p className="text-[#686868] w-[50%]">Doctor Name</p>
                    <p className="text-[#020202] w-[50%]">
                      Dr.{data.doctor.first_name} {data.doctor.last_name}
                    </p>
                  </div>
                  <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                    <p className="text-[#686868] w-[50%]">Patient Name</p>
                    <p className="text-[#020202] w-[50%]">
                      {data.appointment_for == "myself"
                        ? `${data.user_created.first_name} ${data.user_created.last_name}`
                        : `${data.patient_details.first_name} ${data.patient_details.last_name}`}
                    </p>
                  </div>
                  <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                    <p className="text-[#686868] w-[100%] text-[.9rem]">
                      {getTime(data.start_time)} {data.date}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Test Name
                </p>
                <input
                  className="border-[1px] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
                  placeholder="Write down the description"
                  value={reportData.name}
                  onChange={changeHandler}
                  id="name"
                />
              </div>
              {reportData.label !== "" ? (
                <div
                  className={`flex items-center relative px-4 py-2 rounded-md mb-4`}
                  style={{ backgroundColor: `${reportData.label.color}43` }}
                >
                  <EditIcon
                    sx={{
                      color: reportData.label.color,
                      position: "absolute",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setTagsModel(true)}
                  />
                  <div
                    className={`h-[15px] w-[15px] rounded-full mr-3`}
                    style={{
                      backgroundColor:
                        reportData.label && reportData.label.color,
                    }}
                  ></div>
                  <div
                    style={{
                      color: reportData.label && reportData.label.color,
                    }}
                  >
                    {reportData.label && reportData.label.name}
                  </div>
                </div>
              ) : (
                <label
                  className="flex items-center justify-center text-[#979797] mt-[1rem] w-[100%] cursor-pointer"
                  onClick={() => setTagsModel(true)}
                >
                  <PlusIcon className="w-[2rem] h-[2rem]" />
                  <p className="text-[1.1rem] ml-[1rem]">Add Color Tags</p>
                </label>
              )}

              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Description
                </p>
                <textarea
                  className="border-[1px] h-[8rem] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
                  placeholder="Write down the description"
                  value={reportData.description}
                  onChange={changeHandler}
                  id="description"
                />
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Medical prescription
                </p>
                <textarea
                  className="border-[1px] h-[8rem] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
                  placeholder="Write down the description"
                  value={reportData.prescription}
                  onChange={changeHandler}
                  id="prescription"
                />
                {diagrams.map((item, i) => {
                  return (
                    <>
                      <div className="my-1 ">
                        <div
                          className="text-[red] float-right cursor-pointer"
                          onClick={() => removeDoc(item)}
                        >
                          X
                        </div>
                        <div>{item.name}</div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Medical Charges
                </p>
                <div className="border-[1px] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black">
                  <textarea
                    className=" p-[0rem] w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none"
                    placeholder="Write down the description"
                    value={reportData.medicalCharges}
                    onChange={changeHandler}
                    id="medicalCharges"
                  />
                  <div className="bg-[#F9F9F9] p-[1rem] pt-[.5rem] mt-[1rem] rounded-[.5rem] w-[100%] ">
                    <div className="border-b-[1px] border-b-secondaryTextField">
                      <div className=" flex justify-between my-[.5rem]">
                        <input
                          className="w-[90%] bg-[#F9F9F9] text-[1.1rem] p-1"
                          placeholder="Add Detail"
                          value={payment.item}
                          id="item"
                          onChange={rateHandler}
                        />

                        <p className="text-black text-[1.1rem] font-medium">
                          Rs.{" "}
                          <input
                            className="w-[80%] bg-[#F9F9F9] text-[1.1rem] p-1"
                            placeholder="Add price"
                            type="number"
                            id="price"
                            value={payment.price}
                            onChange={rateHandler}
                          />
                        </p>
                      </div>
                    </div>
                    <button
                      className="flex items-center justify-center text-[#979797] mt-[1rem] w-[100%]"
                      onClick={addRate}
                    >
                      <PlusIcon className="w-[2rem] h-[2rem]" />
                      <p className="text-[1.1rem] ml-[1rem]">Add</p>
                    </button>
                    {rates.map((item, i) => {
                      return (
                        <>
                          <div
                            className="float-right mt-1 ml-3 text-[red] cursor-pointer"
                            onClick={() => removeRate(item)}
                          >
                            X
                          </div>
                          <div className="flex justify-between items-center p-1">
                            <div>{item.item}</div>
                            <div>Rs. {item.price}</div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Attachment
                </p>

                <label
                  htmlFor="attachment"
                  className="cursor-pointer flex items-center border-[3px] border-[#CBD3F1] border-dashed py-[1rem] rounded-[1rem] justify-center text-[#979797] mt-[1rem] w-[100%]"
                >
                  <DocumentUploadIcon className="w-[2rem] h-[2rem] text-primary" />
                  <p className="text-[1.1rem] ml-[1rem]">Browse file to add</p>
                </label>
                <input
                  className="hidden"
                  type="file"
                  id="attachment"
                  onChange={addAttachment}
                />
                {attachments.map((item, i) => {
                  return (
                    <>
                      <div className="my-1 ">
                        <div
                          className="text-[red] float-right cursor-pointer"
                          onClick={() => removeAtt(item)}
                        >
                          X
                        </div>
                        <div>{item.name}</div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </ReactSlidingPane>

          <div className="flex items-stretch gap-[1rem] my-[1rem]">
            <div className="w-[50%] bg-[#F4F5F9] py-[1.4rem] px-[2rem] rounded-[.5rem] flex justify-between items-stretch">
              <div className="flex flex-col">
                <img
                  className="w-[7rem] h-[7rem] rounded-[.7rem]"
                  src={
                    data.appointment_for == "myself"
                      ? data.user_created.avatar
                        ? `${BaseUrl}/assets/${data.user_created.avatar}`
                        : DummyUser
                      : data.patient_details.avatar
                      ? `${BaseUrl}/assets/${data.patient_details.avatar}`
                      : DummyUser
                  }
                  alt="..."
                />
                <p className="text-center mt-[.5rem] text-[1rem]">
                  {data.appointment_for == "myself"
                    ? `${data.user_created.first_name} ${data.user_created.last_name}`
                    : `${data.patient_details.first_name} ${data.patient_details.last_name}`}
                </p>
              </div>
              <div className="flex flex-col justify-center pl-[2rem] w-[65%]">
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%]">Full Name</p>
                  <p className="text-[#020202] w-[50%]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.first_name} ${data.user_created.last_name}`
                      : `${data.patient_details.first_name} ${data.patient_details.last_name}`}
                  </p>
                </div>
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%]">Date of birth</p>
                  <p className="text-[#020202] w-[50%]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.date_of_birth}`
                      : `${data.patient_details.date_of_birth}`}
                  </p>
                </div>
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%]">Gender</p>
                  <p className="text-[#020202] w-[50%]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.gender}`
                      : `${data.patient_details.gender}`}
                  </p>
                </div>
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%]">Occupation</p>
                  <p className="text-[#020202] w-[50%]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.occupation}`
                      : `${data.patient_details.occupation}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[50%] bg-[#F4F5F9] rounded-[.5rem] py-[1.1rem] px-[2rem]">
              <p className="font-medium text-[#000000] text-[1.1rem]">
                Appointment Details
              </p>

              <div className="flex flex-col justify-center w-[65%] mt-[1rem]">
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%] flex items-start">
                    <CalendarIcon className="w-[1.5rem] h-[1.5rem] mr-[.6rem] ml-[-.1rem]" />
                    Date
                  </p>
                  <p className="text-[#020202] w-[50%]">{data?.date}</p>
                </div>
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%] flex items-start">
                    <ClockIcon className="w-[1.3rem] h-[1.3rem] mr-[.7rem]" />
                    Time
                  </p>
                  <p className="text-[#020202] w-[50%]">
                    {getTime(data.start_time)}
                  </p>
                </div>
                <div className="w-[100%] flex gap-[1rem] pb-[.5rem]">
                  <p className="text-[#686868] w-[50%] flex items-start">
                    <div className="w-[1.3rem] h-[1.3rem] rounded-[50%] mr-[.7rem] bg-[#6FCF97]"></div>
                    Status
                  </p>
                  <p className="text-[#020202] w-[50%]">{data.status}</p>
                </div>
              </div>
            </div>
          </div>
          <p className="font-bold my-[1rem] text-[1.5rem]">
            Self Declaration Form
          </p>

          {/* /////////////////////// */}
          <div className="w-[100%] bg-[#F4F5F9] py-[1.4rem] px-[2rem] rounded-[.5rem] flex justify-between items-stretch">
            <div className="w-[50%] border-r-[1px] border-r-[#D0D0D0] flex ">
              <div className="w-[80%]">
                {
                  data?.patient_details?.consent_data?.map((ele, idx) =>{
                    return <div className="flex justify-between items-center my-[1rem]">
                    <p>{idx + 1}. {ele?.answer_id?.question?.Question}</p>
                    <button className="bg-primary text-white px-[1.2rem] py-[.3rem] rounded-[.5rem]">
                      {ele?.answer_id?.answer ? "Yes" : "No"}
                    </button>
                    
                  </div>
                  })
                }
                <textarea
                    className="w-[100%] h-[6rem] mt-[.5rem] bg-white rounded-[.5rem]"
                    value={
                      data.appointment_for == "myself"
                        ? data.user_created.allergies
                        : data.patient_details.allergies
                    }
                  ></textarea>
                
                {/* <div className="flex justify-between items-center my-[1rem]">
                  <p>2. Do you have Kidney problem?</p>
                  <button className="bg-primary text-white px-[1.2rem] py-[.3rem] rounded-[.5rem]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.kidney_problem ? "Yes" : "No"}`
                      : `${data.patient_details.kidney_problem ? "Yes" : "No"}`}
                  </button>
                </div>
                <div className="flex justify-between items-center my-[1rem]">
                  <p>3. Do you have Heart Condition?</p>
                  <button className="bg-primary text-white px-[1.2rem] py-[.3rem] rounded-[.5rem]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.heart_condition ? "Yes" : "No"}`
                      : `${
                          data.patient_details.heart_condition ? "Yes" : "No"
                        }`}
                  </button>
                </div>
                <div className="flex justify-between items-center my-[1rem]">
                  <p>4. Do you have Hepatitis?</p>
                  <button className="bg-primary text-white px-[1.2rem] py-[.3rem] rounded-[.5rem]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.hepatitis ? "Yes" : "No"}`
                      : `${data.patient_details.hepatitis ? "Yes" : "No"}`}
                  </button>
                </div> */}
              </div>
            </div>
            {/* <div className="w-[50%] pl-[2rem]">
              <div className="w-[80%]">
                <div className="flex justify-between items-center my-[1rem]">
                  <p>5. Do you Drink Alcohol?</p>
                  <button className="bg-primary text-white px-[1.2rem] py-[.3rem] rounded-[.5rem]">
                    {data.appointment_for == "myself"
                      ? `${data.user_created.drink_alcohol ? "Yes" : "No"}`
                      : `${data.patient_details.drink_alcohol ? "Yes" : "No"}`}
                  </button>
                </div>
                <div className="flex flex-col my-[1rem]">
                  <p>6. Do you any Allegeries?</p>
                  <textarea
                    className="w-[100%] h-[6rem] mt-[.5rem] bg-white rounded-[.5rem]"
                    value={
                      data.appointment_for == "myself"
                        ? data.user_created.allergies
                        : data.patient_details.allergies
                    }
                  ></textarea>
                </div>
              </div>
            </div> */}
          </div>

          {/* ///////////////////////////////////////// */}
          
          {/* //test Results */}
          {data.Reports ? (
            <>
              <p className="font-bold my-[1rem] text-[1.5rem] mb-8">
                Test Results
              </p>
              <div onClick={() => sendPage(data.Reports.id)}>
                <ReportThumbnail
                  name={data.Reports.name}
                  date={data.Reports.date_created}
                  color={data.Reports.label}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {data.Reports && data.Reports.name ? (
            <></>
          ) : (
            <>
              {/* //buttons */}
              {data.status !== "Cancelled" && (
                <div className="flex justify-start gap-[1rem] my-[2rem] mb-[5rem]">
                  {/* <button
                  onClick={() => setIsOpen(true)}
                  className="bg-primary text-white w-[15rem] py-[.9rem] rounded-[.5rem] flex justify-center items-center"
                >
                  <UploadIcon className="w-[1.1rem] h-[1.1rem]" />
                  <p className="ml-[1rem]">Upload Report</p>
                </button> */}
                  {/* <button className="bg-[#F4F5F9] text-primary w-[15rem] py-[.9rem] rounded-[.5rem] flex justify-center items-center">
          <p className="ml-[1rem]">Upload Payment Status</p>
        </button> */}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div>No such Appointment yet</div>
        </>
      )}
      <ColorTags
        state={tagsModel}
        handleClose={tagsModelClose}
        getLabel={changeLabel}
      />
    </div>
  );
};

export default UserProfile;
