import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as DownArrowIcon } from "../../../aseets/downArrowIcon.svg";
import Dummy from "../../../aseets/doctorAvatar.jpg";
import AppointmentCard from "../../../components/AppointmentCard";
import OutsideClickHandler from "react-outside-click-handler";
import axios from "axios";
import { Grid } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
const BaseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};
function Appointments() {
  let [data, setData] = useState([]);
  let [doctors, setDoctors] = useState([]);
  const [isDoctorOpen, setIsDoctorOpen] = useState(false);
  let [selectedDoctor, setSelectedDoctor] = useState({});
  let [input, setInput] = useState("");
  let [appointments, setAppointments] = useState([]);
  let changeHandler = (e) => {
    setInput(e.target.value);
    let arr = filterByValue(e.target.value);
    setDoctors([{ first_name: "All", last_name: "" }, ...arr]);
    setIsDoctorOpen(true);
  };

  const clickHandler = () => {
    setInput(input);
    let arr = filterByValue("");
    setDoctors([{ first_name: "All", last_name: "" }, ...arr]);
    setIsDoctorOpen(true);
    console.log(doctors);
  };

  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/users?filter[role][_eq]=e2709430-b9bc-4945-893b-e8f9b223f63a`,
        config
      )
      .then((res) => {
        // console.log("data", res?.data?.data)
        setData(res.data.data);
        setSelectedDoctor("");
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("sort[]", "-date");
    if (selectedDoctor.id) {
      params.append("filter[doctor][_eq]", selectedDoctor.id);
    }
    axios
      .get(`${BaseUrl}/items/Appointment?fields=*.*&${params}`)
      .then((res) => {
        console.log(res);
        let myData = res.data.data;
        console.log("myData", myData);
        setAppointments(myData);
      });
  }, [selectedDoctor]);
  let reloadData1 = () => {
    const params = new URLSearchParams();
    if (selectedDoctor.id) {
      params.append("filter[doctor][_eq]", selectedDoctor.id);
    }
    params.append("sort[]", "-date");
    axios
      .get(`${BaseUrl}/items/Appointment?fields=*.*&${params}`)
      .then((res) => {
        console.log(res);
        setAppointments(res.data.data);
      });
  };
  function filterByValue(value) {
    return data.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  let closeDrop = () => {
    // setDoctors([]);
    setIsDoctorOpen(false);
  };

  // handling popup open close events
  function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const innerRef = useRef(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value
    useEffect(() => {
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(e.target)
        )
          callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
  }

  const popupRef = useOuterClick((ev) => {
    if (isDoctorOpen) {
      setIsDoctorOpen(false);
    }
  });
  let changeSelectedDoctor = (item) => {
    if (item.first_name === "All") {
      setInput(`All`);
    } else {
      setInput(`Dr. ${item.first_name} ${item.last_name}`);
    }

    setSelectedDoctor(item);
    // setDoctors([]);
    setIsDoctorOpen(false);
  };
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const doctor_id = searchParams.get("doctor");

  useEffect(() => {
    if (doctor_id) {
      axios
        .get(`${BaseUrl}/users/${doctor_id}?fields=*`)
        .then((res) => {
          changeSelectedDoctor(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [doctor_id]);
  return (
    <div className="mt-[1rem]">
      <Link to="/admin/appointments-create">
        <div className=" w-fit float-right bg-[#2EB9AC] px-[25px] py-[15px] text-[#fff] rounded-lg mb-[2rem] text-[18px] mt-4 cursor-pointer">
          Create new appointment
        </div>
      </Link>
      <div className="text-[20px] font-semibold">Appointments</div>

      <div className="mt-[3rem]">
        <p className="text-[1.35rem] mb-[.3rem] font-medium">Doctor Name</p>

        <div className="relative w-fit">
          <div ref={popupRef}>
            <input
              className="border-[1px] p-[1rem] pr-[4rem] border-secondaryTextField rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
              value={input}
              id="doctorInput"
              onFocus={() => setIsDoctorOpen(true)}
              onChange={changeHandler}
            />
            <div
              className="w-[2rem] h-[2rem] bg-primary flex p-[.5rem] justify-center items-center text-white rounded-[.5rem] absolute right-[1rem] top-[50%] translate-y-[-50%] cursor-pointer"
              onClick={clickHandler}
            >
              <DownArrowIcon
                onClick={() => document.getElementById("doctorInput").focus()}
              />
            </div>
            {doctors.length > 0 && isDoctorOpen && (
              <div className="absolute top-[60px] w-[100%] bg-white border border-gray-400 rounded-md max-h-[200px] overflow-y-auto">
                {doctors.map((item, i) => {
                  return (
                    <div
                      className="flex items-center p-2 hover:bg-[#e5e5e5] cursor-pointer"
                      onClick={() => changeSelectedDoctor(item)}
                    >
                      <img
                        className="w-[40px] h-[40px] rounded-md mr-1 font-semibold"
                        src={Dummy}
                        alt=""
                      />
                      <div>
                        {item?.first_name == "All"
                          ? "All"
                          : `Dr.${item.first_name} ${item.last_name}`}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center px-[1.5rem] py-[1rem] my-[2rem]  bg-[#F4F5F9] rounded-lg">
        <img
          src={Dummy}
          className="w-[45px] h-[45px] rounded-full mr-[1rem]"
          alt=""
        />
        <div className="text-[1rem] font-bold">
          {selectedDoctor
            ? selectedDoctor.first_name !== "All"
              ? `Dr.${selectedDoctor.first_name && selectedDoctor.first_name}
            ${selectedDoctor.last_name && selectedDoctor.last_name}`
              : "All"
            : ""}
        </div>
      </div>
      <div>
        <Grid container spacing={2}>
          {appointments.map((item, i) => {
            return (
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <AppointmentCard data={item} reloadData={reloadData1} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}

export default Appointments;
