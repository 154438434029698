import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./ProtectedScreens/home";
import AdminNavbar from "../../components/Navbar/adminNavbar";
import AdminSidebar from "../../components/Sidebar/adminSidebar";
import Notifications from "./ProtectedScreens/Notifications";
import Payment from "./ProtectedScreens/Payment";
import { useEffect } from "react";
import Appointments from "./ProtectedScreens/Appointments";
import CreateAppointment from "./ProtectedScreens/CreateAppointment";
import Report from "../Report";
import UserProfile from "../Admin/ProtectedScreens/UserProfile";
import Calendar from "../../components/AppointmentCalendar";
import FinanceReport from "./ProtectedScreens/FinanceReport";
const Admin = () => {
  let navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("role") == "6e244d53-82c5-4aa2-8d90-9e737f8da3ca"
    ) {
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <div>
        <AdminSidebar />
        <div className="w-[100%] pl-[19rem] pr-[2rem] relative">
          <div className="w-[100%] max-w-[1300px]">
            <AdminNavbar />
            <div className="mt-6">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/appointments" element={<Appointments />} />
                <Route path="/appointment/:id" element={<UserProfile />} />
                <Route
                  path="/appointments-create"
                  element={<CreateAppointment />}
                />
                <Route path="/finance-report" element={<FinanceReport />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/report/:id" element={<Report />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
