import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedScreens from "./ProtectedScreens";
import { useNavigate } from "react-router";
const Dentist = () => {
  let navigate = useNavigate()
  useEffect(()=>{
    if(localStorage.getItem("role") === "e2709430-b9bc-4945-893b-e8f9b223f63a"){
      
    }else{
      navigate("/login")
    }
  },[])
  return (
    <>
      <div>
        <Routes>
          <Route path="/*" element={<ProtectedScreens />} />
        </Routes>
      </div>
    </>
  );
};

export default Dentist;
