import React, { useEffect, useRef, useState } from "react";
import DateIcon from "../../../aseets/dateIcon.svg";
import TableItem from "../../../components/FinanceReport/TableItem";
import TableHeader from "../../../components/FinanceReport/TableHeader";
import FinanceButton from "../../../components/FinanceReport/FinanceButton";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import notification from "../../../components/Toast";

const BaseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};

const getPatientReport = async (
  start_date = "2023-08-13",
  end_date = "2023-08-13"
) => {
  try {
    let filterUrl = "";
    if (start_date || end_date) {
      filterUrl += `&filter={%22_and%22:[{%22Reports%22:{%22date_created%22:{%22_gt%22:%22${start_date}T00:00:00%22}}},{%22Reports%22:{%22date_created%22:{%22_lt%22:%22${end_date}T23:59:59%22}}},{%22status%22:{%22_eq%22:%22Completed%22}}]}`;
    } else {
      filterUrl += "&filter[status][_eq]=Completed";
    }
    const res = await axios.get(
      `https://cms.drtsdentalcare.com/items/Appointment?fields=*,Reports.*,patient_details.*${filterUrl}&limit=-1`
    );
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
export default function FinanceReport() {
  const [start_date, set_start_date] = useState("");
  const [end_date, set_end_date] = useState("");
  const dateFromInputRef = useRef(null);
  // const dateToInputRef = useRef(null);
  const [data, setData] = useState([]);
  const convertPDF = useRef();
  const [printing, setPrinting] = useState(false);

  const handleFromLabelClick = () => {
    dateFromInputRef.current.click();
  };

  useEffect(() => {
    getPatientReport(start_date, end_date).then((res) => {
      const data = res?.data || [];
      setData(data);
      console.log(data);
    });
  }, [start_date, end_date]);

  const genToday = () => {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    return maxDate;
  };
  const downloadPDF = useReactToPrint({
    content: () => convertPDF.current,
    documentTitle: "Patient_Report_" + new Date().toISOString(),
    // onAfterPrint: () => {
    //   if (window.confirm("Did you successfully download the report?")) {
    //     notification("success", "Report Downloaded Successfully");
    //   } else {
    //     notification("error", "Download Cancelled");
    //   }
    // },
  });

  return (
    <div className="">
      <div className="text-[20px] font-semibold">
        Generate Finance Transactions Received
      </div>
      <div className="mt-[3rem]  grid grid-cols-5 gap-5 mb-5">
        <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
          <div className="flex items-center justify-start gap-4 mb-4">
            <p className="w-[100px]">From Date:</p>
            <input
              type="date"
              value={start_date}
              className="border"
              onChange={(event) => set_start_date(event.target.value)}
              ref={dateFromInputRef}
            />
            <label onClick={handleFromLabelClick}>
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={DateIcon}
                alt="..."
              />
            </label>
          </div>
          <div className="flex items-center justify-start gap-4 mb-4">
            <p className="w-[100px]">To Date:</p>
            <input
              type="date"
              value={end_date}
              max={genToday()}
              className="border"
              id="toDate"
              onChange={(event) => set_end_date(event.target.value)}
            />
            <label htmlFor="toDate">
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={DateIcon}
                alt="..."
              />
            </label>
          </div>
        </div>

        <div className="col-span-5 lg:col-span-3 flex items-start justify-center  flex-wrap gap-6">
          {/* <FinanceButton value={"Generate Report"} /> */}
          <FinanceButton value={"Download Report"} handleClick={downloadPDF} />
          <FinanceButton
            value={"Clear All"}
            handleClick={() => {
              set_end_date("");
              set_start_date("");
            }}
            isDisabled={!start_date || !end_date}
          />
        </div>
      </div>

      <div className="mb-10">
        <div className="overflow-x-auto">
          <div ref={convertPDF} className="w-full">
            <table className="min-w-[70rem] border border-black">
              <thead>
                <TableHeader />
              </thead>
              <tbody>
                {data?.length > 0 &&
                  data?.map((d) =>
                    d.Reports !== null ? (
                      <TableItem key={d.id} data={d} />
                    ) : null
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
