import { Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import SearchIcon from "../../../aseets/search-normal.png";
import DoctorCard from "../../../components/admin/common/doctorCard";
const BaseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};
function Home() {
  let navigate = useNavigate();
  let [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/users?filter[role][_eq]=e2709430-b9bc-4945-893b-e8f9b223f63a`,
        config
      )
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  let [value, setValue] = useState("");
  let changeHandler = (e) => {
    setValue(e.target.value);
    if (e.target.value == "") {
      axios
        .get(
          `${BaseUrl}/users?filter[role][_eq]=e2709430-b9bc-4945-893b-e8f9b223f63a`,
          config
        )
        .then((res) => {
          setData(res.data.data);
        });
    } else {
      axios
        .get(
          `${BaseUrl}/users?filter={"_and":[{"_or":[{"first_name":{"_icontains":"${e.target.value}"}},{"last_name":{"_icontains":"${e.target.value}"}}]},{"role":{"_eq":"e2709430-b9bc-4945-893b-e8f9b223f63a"}}]}`,
          config
        )
        .then((res) => {
          if (res.data.data) {
            setData(res.data.data);
          } else {
            setData([]);
          }
        });
    }
  };
  let clickedOnDoctorCard = (item) => {
    navigate(`/admin/appointments?doctor=${item.id}`);
  };

  
  return (
    <div>
      <div className="flex items-center justify-between">
      <div>
        <div className="relative">
          <img className="absolute top-[8px] left-[15px]" src={SearchIcon} />
          <input
            type="text"
            className="h-[40px] px-[50px] py-[10px] bg-[#F4F5F9] rounded-lg focus:outline-none"
            placeholder="Search Doctor"
            value={value}
            onChange={changeHandler}
          />
        </div>
      </div>
        <Link to="/admin/appointments-create">
          <div className=" w-fit ml-auto bg-[#2EB9AC] px-[25px] py-[15px] text-[#fff] rounded-lg mb-[2rem] text-[18px] mt-4 cursor-pointer">
            Create new appointment
          </div>
        </Link>
      </div>
      
      <div className="flex items-center justify-between mb-[30px]">
        <h4 className="text-[20px] font-semibold">Doctor List</h4>
        {/* <div className="relative">
          <img className="absolute top-[8px] left-[15px]" src={SearchIcon} />
          <input
            type="text"
            className="h-[40px] px-[50px] py-[10px] bg-[#F4F5F9] rounded-lg focus:outline-none"
            placeholder="Search Doctor"
            value={value}
            onChange={changeHandler}
          />
        </div> */}
      </div>
      <Grid container spacing={3}>
        {data.map((item, i) => {
          return (
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={12}
              key={i}
              onClick={() => clickedOnDoctorCard(item)}
            >
              <DoctorCard data={item} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default Home;
