/* eslint-disable no-unused-vars */
import axios from "axios";
import { differenceInDays, lastDayOfMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import AppointmentCard from "../AppointmentCardAdmin";
import { Link } from "react-router-dom";

const BaseUrl = process.env.REACT_APP_BASE_URL;

const CustomCalendar = () => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [date, setDate] = useState(new Date());
  date.setDate(1);
  const [currDate, setCurrDate] = useState(new Date());
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());
  const [bookedDates, setBookedDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  let [allAppointments, setAllAppointments] = useState([]);
  const [loader, setLoader] = useState(false);

  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  const prevLastDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    0
  ).getDate();

  const firstDayIndex = date.getDay();

  const renderCalendar = () => {
    const currentMonthDays = Array.from({ length: lastDay }, (_, i) => i + 1);
    const previousMonthDays = Array.from(
      { length: firstDayIndex },
      (_, i) => prevLastDay - firstDayIndex + i + 1
    );
    const nextMonthDays = Array.from(
      { length: 7 - ((lastDay + firstDayIndex) % 7) },
      (_, i) => i + 1
    );

    const cellStyle =
      "w-[35px] cursor-pointer h-[35px] sm:w-[60px] sm:h-[60px] inline-flex justify-center items-center rounded-md";

    const allDays = [
      ...previousMonthDays.map((day) => (
        <td key={day.toString()} className="py-4 text-gray-400 text-center">
          <span className={`${cellStyle} bg-gray-50`}>{day}</span>
        </td>
      )),
      ...currentMonthDays.map((day) => {
        const isCurrDate =
          differenceInDays(
            new Date(currDate).setHours(0, 0, 0, 0),
            new Date(
              new Date(`${date.getFullYear()}-${currMonth + 1}-${day}`)
            ).setHours(0, 0, 0, 0)
          ) === 0;
        const bookedDate = bookedDates.includes(`${day}`);
        return (
          <td key={day.toString()} className="py-4 text-center">
            <span
              onClick={() =>
                setCurrDate((date) => {
                  return new Date(
                    new Date(
                      `${date.getFullYear()}-${currMonth + 1}-${day}`
                    ).setHours(23, 0, 0, 0)
                  );
                })
              }
              className={`${cellStyle} ${
                isCurrDate
                  ? "bg-[#2EB9AC] text-[#fff]"
                  : bookedDate
                  ? "bg-[#8EF4EB]"
                  : "bg-[#E6E6E6]"
              }`}
            >
              {day}
            </span>
          </td>
        );
      }),
      ...nextMonthDays.map((day, index) => (
        <td key={day.toString()} className="py-4 text-center text-gray-400">
          <span className={`${cellStyle} bg-gray-50`}>{day}</span>
        </td>
      )),
    ];

    const rows = [];
    let cells = [];

    allDays.forEach((day, index) => {
      if (index % 7 === 0 && cells.length > 0) {
        rows.push(<tr key={index}>{cells}</tr>);
        cells = [];
      }
      cells.push(day);
    });

    if (cells.length > 0) {
      rows.push(<tr>{cells}</tr>);
    }

    return rows;
  };

  const handlePrevClick = () => {
    const newDate = new Date(date.setMonth(date.getMonth() - 1));
    setDate(newDate);
    setCurrMonth(newDate.getMonth());
  };

  const handleNextClick = () => {
    const newDate = new Date(date.setMonth(date.getMonth() + 1));
    setDate(newDate);
    setCurrMonth(newDate.getMonth());
  };

  let reloadData1 = () => {
    setLoader((prev) => !prev);
    const startDate = new Date(new Date().setMonth(currMonth)).setDate(1);
    let lastDate = lastDayOfMonth(new Date().setMonth(currMonth));
    lastDate.setHours(23, 0, 0, 0);

    const startDateStr = new Date(startDate).toISOString().substr(0, 10);
    const lastDateStr = new Date(lastDate).toISOString().substr(0, 10);
    console.log({ startDateStr, lastDateStr });
    const params = new URLSearchParams();
    params.append(
      "filter",
      JSON.stringify({
        _and: [
          { date: { _gte: startDateStr } },
          { date: { _lte: lastDateStr } },
        ],
      })
    );
    axios
      .get(`${BaseUrl}/items/Appointment?fields=*.*&${params}`)
      .then((res) => {
        setAllAppointments(res.data.data);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.append(
      "filter",
      JSON.stringify({ date: { _eq: currDate.toISOString().substr(0, 10) } })
    );
    params.append("sort", "datetime");
    axios
      .get(`${BaseUrl}/items/Appointment?fields=*.*&${params}`)
      .then((res) => {
        console.log(res);
        setAppointments(res.data.data);
      });
  }, [currDate, loader]);

  useEffect(() => {
    setBookedDates([]);
    const startDate = new Date(new Date().setMonth(currMonth)).setDate(1);
    let lastDate = lastDayOfMonth(new Date().setMonth(currMonth));
    lastDate.setHours(23, 0, 0, 0);

    const startDateStr = new Date(startDate).toISOString().substr(0, 10);
    const lastDateStr = new Date(lastDate).toISOString().substr(0, 10);
    console.log({ startDateStr, lastDateStr });
    const params = new URLSearchParams();
    params.append(
      "filter",
      JSON.stringify({
        _and: [
          { date: { _gte: startDateStr } },
          { date: { _lte: lastDateStr } },
        ],
      })
    );
    console.log(`${params}`);
    axios
      .get(`${BaseUrl}/items/Appointment?fields=*.*&${params}`)
      .then((res) => {
        let bookedDates = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const appointment = res.data.data[i];
          if (
            !bookedDates.includes(`${new Date(appointment.date).getDate()}`)
          ) {
            setBookedDates((bookedDates) => {
              return [
                ...bookedDates,
                `${new Date(appointment.date).getDate()}`,
              ];
            });
          }
        }
        let myData = res.data.data;
        setAllAppointments(myData.reverse());
      });
  }, [currMonth]);

  return (
    <div className="flex justify-evenly">
      <div className="container mx-auto my-10 mt-0 p-2 w-[60%]">
        <div className="bg-primary rounded-xl px-4 py-2 flex items-center justify-between gap-4  flex-col sm:flex-row">
          <div className="flex items-center gap-4">
            <p className="text-white text-2xl">
              {date.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
            </p>
            <button
              className="bg-white text-primary rounded-md py-2.5 px-4"
              onClick={handlePrevClick}
            >
              &lt;
            </button>
            <button
              className="bg-white text-primary rounded-md py-2.5 px-4"
              onClick={handleNextClick}
            >
              &gt;
            </button>
          </div>
          <Link
            to={"/admin/appointments-create"}
            className="bg-[#fff] border-greyBorder border-0 px-4 py-3 rounded-xl font-semibold text-primary"
          >
            Create a new appointment
          </Link>
        </div>
        <table className="table-auto w-full mt-8">
          <thead>
            <tr>
              {daysOfWeek.map((day) => {
                return (
                  <th key={day.toString()} className="text-[#000000]/60">
                    {day}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{renderCalendar()}</tbody>
        </table>

        <div className="mt-[3rem] flex justify-center items-center gap-[3rem]">
          <div className="flex items-center">
            <div className="bg-[#8EF4EB] w-[2.5rem] h-[2.5rem] rounded-[.3rem] mr-[1rem]"></div>
            <p className="font-medium text-[1.2rem]">Booked Date</p>
          </div>
          <div className="flex items-center">
            <div className="bg-[#E6E6E6] w-[2.5rem] h-[2.5rem] rounded-[.3rem] mr-[1rem]"></div>
            <p className="font-medium text-[1.2rem]">Not Booked</p>
          </div>
        </div>
      </div>
      <div className="w-[37%]">
        <p className="font-bold text-[1.7rem] mt-[.5rem]">Appointments List</p>
        <div className="flex flex-col gap-[.8rem] overflow-y-auto max-h-[90vh] appointment-scroll-bar">
          {appointments.length === 0 ? (
            <p className="mt-[2rem] text-[1.2rem]">
              No appointments found for {currDate.toDateString()}
            </p>
          ) : (
            <>
              {appointments.map((item, i) => {
                console.log(item);
                return (
                  <AppointmentCard
                    showDoctor={true}
                    key={item?.id}
                    data={item}
                    reloadData={reloadData1}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomCalendar;
