import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dentist from "./screens/Dentist";
import Admin from "./screens/Admin";
import Login from "./screens/Dentist/Login";
import { useEffect } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import app from "./firebase";
import Privacy_policy from "./screens/privacy_policy";

const App = () => {
  const askNotificationsPermission = () => {
    Notification.requestPermission()
      .then((res) => {
        console.log(res);
        if (res === "granted") {
          console.log("Notification permission granted.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const generateDeviceToken = () => {
    console.log("function chal raha hai");
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      console.log("------------ received something -----------------");
      console.log(payload);
      console.log("------------ received something -----------------");
    });

    // console.log(messaging);
    // getToken(messaging, {
    //   vapidKey:
    //     "BH7fXYBtXQydO-QdrQAHR5xrk5oiZMSrms4-DCuJtV4UPn5Vy4Q82hlB1GUTBSDl2BPg4jdz8Mv9pnQJD1MaR7I",
    // })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       console.log("-------------- got token -----------------");
    //       console.log(currentToken);
    //       console.log("-------------- got token -----------------");
    //       messaging
    //         .subscribeToTopic([currentToken], "admin")
    //         .then((response) => {
    //           // See the MessagingTopicManagementResponse reference documentation
    //           // for the contents of response.
    //           console.log("Successfully subscribed to topic:", response);
    //         })
    //         .catch((error) => {
    //           console.log("Error subscribing to topic:", error);
    //         });

    //       // Send the token to your server and update the UI if necessary
    //       // ...
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       );
    //       // ...
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //     // ...
    //   });
  };
  useEffect(() => {
    askNotificationsPermission();
    generateDeviceToken();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Dentist />} />
        <Route path="/privacy" element={<Privacy_policy />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
