import React from "react";
import Doctor1 from "../../../aseets/doctorAvatar.jpg";
const BaseUrl = process.env.REACT_APP_BASE_URL;
function doctorCard({data}) {
  return (
    <div
      className="p-[1rem] rounded-xl"
      style={{ boxShadow: "0px 4px 11px rgba(158, 158, 158, 0.25)" }}
    >
      <img
        src={data.avatar ? `${BaseUrl}/assets/${data.avatar}` : Doctor1}
        className="w-[100%] h-[175px] object-cover mb-[1rem] rounded-xl"
      />
      <div className="text-[18px] mb-[10px]">Dr.{data.first_name} {data.last_name}</div>
      <div className="text-[18px] mb-[10px] text-[#D0D0D0]">{data.occupation}</div>
    </div>
  );
}

export default doctorCard;
