import { Grid } from '@mui/material'
import React from 'react'

function PaymentBox() {
  return (
    <div className="bg-[#F4F5F9] px-[25px] py-[15px] rounded-xl mb-4">
            <Grid container spacing={1}>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#505050] font-light">#12</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#505050] font-light">Akshita Patel</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#505050] font-light">
                  Dr. Akshita Patel
                </div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#505050] font-light">Pending</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#2EB9AC] underline font-semibold">
                  Download Receipt
                </div>
              </Grid>
            </Grid>
          </div>
  )
}

export default PaymentBox