import { useEffect, useState } from "react";

const Navbar = () => {
  let [data, setData] = useState({});
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);
  return (
    <div className="bg-white w-[100%] max-w-[1300px] py-[.7rem] border-b-2 border-b-[#D0D0D0]">
      <h1 className="text-[2rem] font-semibold">Welcome Dr. TS Dental Care</h1>
      <p className="text-[#959595]">Happiness is highest form of wealth</p>
    </div>
  );
};

export default Navbar;
