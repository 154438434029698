import DentistLogo from "../../aseets/dentistAppLogo.png";
import DummyUser from "../../aseets/logo.png";
import ProfilRing from "../../aseets/profileRing.png";
import ActiveHomeIcon from "../../aseets/activeHomeIcon.svg";
import PaymentIcon from "../../aseets/paymentIcon.svg";
import NotificationIcon from "../../aseets/notificationIcon.svg";
import PrivacyPolicyIcon from "../../aseets/privacyPolicyIcon.svg";
import LogoutIcon from "../../aseets/logoutIcon.svg";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import HomeIcon from "../../aseets/homeIcon.svg";
import ActivePrivacyPolicyIcon from "../../aseets/activePrivacyPolicyIcon.svg";
const BaseUrl = process.env.REACT_APP_BASE_URL;

const Sidebar = () => {
  const navigate = useNavigate();
  let [data, setData] = useState({});
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setData(JSON.parse(localStorage.getItem("user")));
    }
    console.log(window.location.href);
  }, []);
  let logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  let navMe = (item) => {
    // alert(item)
  };
  return (
    <div
      className={`scrollbar-thumb-primary scrollbar-thumb-rounded-xl scrollbar-track-white scrollbar-thin w-[17rem] h-[100vh] overflow-y-auto fixed left-0 top-0 py-[5vh] pl-[2rem] text-white bg-primary`}
      style={{ zIndex: "999" }}
    >
      <div className="min-h-[90%]">
        <div className="w-[100%] pr-[2rem] flex flex-col justify-center items-center">
          <img
            className="w-[4rem] h-[4rem] rounded-[.7rem]"
            src={DentistLogo}
            alt="..."
          />
          <h2 className="text-[1.7rem] mt-[.5rem] font-semibold">
            Dentist App
          </h2>
        </div>
        <div className="flex flex-col justify-center items-center pr-[2rem] mt-[1rem]">
          <div className="flex justify-center items-center h-[6rem] w-[10rem] rounded-[50%] relative">
            {/* <img className="h-[100%] w-[100%]" src={ProfilRing} alt="..." /> */}
            <img
              className="w-[100%] h-[100%] object-contain absolute"
              src={data.avatar ? `${BaseUrl}/assets/${data.avatar}` : DummyUser}
              alt="..."
            />
          </div>
          <p className="text-[1.1rem] mt-[.5rem] font-semibold">
            {data.first_name} {data.last_name}
          </p>
        </div>

        <div className="flex flex-col mt-[1rem]">
          <Link to="/">
            <div
              className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
                window.location.href.endsWith("/privacy-policy")
                  ? "bg-primary"
                  : "bg-white"
              } cursor-pointer`}
            >
              {
                 window.location.href.endsWith("/privacy-policy") ? (
                <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={HomeIcon}
                alt="..."
              />
                 ) : (
                  <img
                  className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                  src={ActiveHomeIcon}
                  alt="..."
                />
                 )
              }
              
              <p
                className={`${
                  !window.location.href.endsWith("/privacy-policy")
                    ? "text-primary"
                    : "text-white "
                } mt-[.2rem] font-medium`}
              >
                Home
              </p>
            </div>
          </Link>


          {/* <Link to="/privacy-policy">
            <div
              className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
                window.location.href.endsWith("/privacy-policy")
                  ? "bg-white "
                  : "bg-primary "
              } cursor-pointer`}
              onClick={() => navMe("/privacy-policy")}
            >
              {
              window.location.href.endsWith("/privacy-policy") ? (
                <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={ActivePrivacyPolicyIcon}
                alt="..."
              />
              ) : (
                <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={PrivacyPolicyIcon}
                alt="..."
              />
              )
              }
             
              <p
                className={`${
                  window.location.href.endsWith("/privacy-policy")
                    ? "text-primary "
                    : "text-white "
                } mt-[.2rem] font-medium`}
              >
                Privacy Policy
              </p>
            </div>
          </Link> */}

        </div>
      </div>
      <div
        className="min-h-[10%] flex items-center pl-[1.3rem] pr-[2rem] cursor-pointer"
        onClick={logout}
      >
        <img
          className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
          src={LogoutIcon}
          alt="..."
        />
        <p className="text-white font-medium ">Log Out</p>
      </div>
    </div>
  );
};

export default Sidebar;
