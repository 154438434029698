import React, { useEffect, useState } from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import CloseIcon from '@mui/icons-material/Close';
import teeth from "../aseets/teethImage.png";
import FileOpenRoundedIcon from "@mui/icons-material/FileOpenRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import EditReport from "../components/EditReport";
import { Alert } from "@mui/material";
import notification from "../components/Toast";
const BaseUrl = process.env.REACT_APP_BASE_URL;

function Report() {
  let param = useParams();

  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [OpenEdit, setOpenEdit] = useState(false)
  const [attachementUrl, setAttachemntUrl] = useState('')
  const [attachementPopup, setAttachemntPopup] = useState(false)
  const [doctorName,setDoctorName] = useState('')
  const [patientName ,setPatientName] = useState('')
  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  };

  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/items/Reports?filter[id][_eq]=${param.id}&fields=*.*`,
        config
      )
      .then((res) => {
        console.log(res);
        if (res.data.data.length > 0) {
          console.log(res.data.data[0]);
          console.log(attachementUrl)
          setData(res.data.data[0]);
          if (res.data.data[0].doctor_name[0]) {
            axios
              .get(
                `${BaseUrl}/users?filter[id][_eq]=${res.data.data[0].doctor_name[0].directus_users_id}`
              )
              .then((res) => {
                console.log(res.data.data[0].first_name);
                if (res.data.data.length > 0) {
                  setDoctorName(`${res.data.data[0].first_name} ${res.data.data[0].last_name}`)
                  // setNames({
                  //   ...names,
                  //   doctorName: `${res.data.data[0].first_name} ${res.data.data[0].last_name}`,
                  // });
                } else {
                  // setNames({
                  //   ...names,
                  //   doctorName: `Doctor Not mentioned`,
                  // });
                }
              });
          }
          if (res.data.data[0].patient_name[0]) {
            axios
              .get(
                `${BaseUrl}/users?filter[id][_eq]=${res.data.data[0].patient_name[0].directus_users_id}`
              )
              .then((res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                  setPatientName(`${res.data.data[0].first_name} ${res.data.data[0].last_name}`)
                  // setNames({
                  //   ...names,
                  //   patientName: `${res.data.data[0].first_name} ${res.data.data[0].last_name}`,
                  // });
                } else {
                  // setNames({
                  //   ...names,
                  //   patientName: `Others`,
                  // });
                }
              });
          }
        } else {
          navigate("/404")
        }

      });
  }, []);

  let reloadParentData = () => {
    axios
      .get(
        `${BaseUrl}/items/Reports?filter[id][_eq]=${param.id}&fields=*.*`,
        config
      )
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length > 0) {
          setData(res.data.data[0]);
          if (res.data.data[0].doctor_name[0]) {
            axios
              .get(
                `${BaseUrl}/users?filter[id][_eq]=${res.data.data[0].doctor_name[0].directus_users_id}`
              )
              .then((res) => {
                console.log(res.data.data);
                if (res.data.data.length > 0) {
                  setDoctorName(`${res.data.data[0].first_name} ${res.data.data[0].last_name}`)
                  // setNames({
                  //   ...names,
                  //   doctorName: `${res.data.data[0].first_name} ${res.data.data[0].last_name}`,
                  // });
                } else {
                  // setNames({
                  //   ...names,
                  //   doctorName: `Doctor Not mentioned`,
                  // });
                }
              });
          }
          if (res.data.data[0].patient_name[0]) {
            axios
              .get(
                `${BaseUrl}/users?filter[id][_eq]=${res.data.data[0].patient_name[0].directus_users_id}`
              )
              .then((res) => {
                console.log(res.data);
                if (res.data.data.length > 0) {
                  setPatientName(`${res.data.data[0].first_name} ${res.data.data[0].last_name}`)
                  // setNames({
                  //   ...names,
                  //   patientName: `${res.data.data[0].first_name} ${res.data.data[0].last_name}`,
                  // });
                } else {
                  // setNames({
                  //   ...names,
                  //   patientName: `Others`,
                  // });
                }
              });
          }
        } else {
          navigate("/404")
        }

      });
    setOpenEdit(false)
    document.getElementById("alert").style.display = "flex"
    setTimeout(() => {
      if (document.getElementById("alert")) {
        document.getElementById("alert").style.display = "none"
      }
    }, 4000)
  }
  function convertToIST(utcTimeString) {
    // create a new Date object from the UTC time string
    var utcTime = new Date(utcTimeString);

    // create a new Date object, representing the same time in the IST time zone
    var ISTTime = new Date(utcTime.getTime() + 330 * 60 * 1000);

    return `${ISTTime.toDateString()}`;
  }

  // let [names, setNames] = useState({
  //   doctorName: "",
  //   patientName: "",
  // });

  let goBack = () => {
    navigate(-1)
  }
  let closeEdit = () => {
    setOpenEdit(false)
  }


// console.log("data", data?.attachments)


  return (
    <>


      <div className="relative">

      
        <div className={`attachement-popup absolute bg-white h-full w-full duration-300 flex flex-col   z-10 ${attachementPopup ? 'flex ' : 'hidden '}  `}>
          <div className="attachment-option  flex flex-row justify-between items-start sticky bg-primary  rounded-t-lg py-2 px-3  top-10">

            <CloseIcon onClick={() => setAttachemntPopup(!attachementPopup)} className="text-white rounded-full cursor-pointer hover:text-red-900 text-xl " />
            <a href={`${BaseUrl}/assets/${attachementUrl}?download`}>

              <button className="text-white mr-4 " >Download</button>
            </a>
          </div>
          <div className="attachment-content sticky top-20 z-1  bg-white h-3/4 w-full flex border-2 overflow-y-sroll justify-center  ">
            {data.attachments &&
              data.attachments.map((attachement) => {
                return (
                  <iframe  className="" src={`${BaseUrl}/assets/${attachement.directus_files_id}`} width={"1200"} height={"600"} />
                )
              })}
          </div>


        </div>
        <Alert severity="success" sx={{ position: "sticky", top: "0px", display: "none" }} id="alert">Report Updated Successfully</Alert>
        {data.name ? (
          <>
            <div className="flex justify-between items-center flex-wrap mb-8">

              <KeyboardArrowLeftRoundedIcon
                sx={{
                  fontSize: "3rem",
                  bgcolor: "#2EB9AC",
                  color: "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={goBack}
              />
              {
                !window.location.href.includes("admin") && <div className="text-white bg-primary py-3 px-6 font-bold rounded-md cursor-pointer" onClick={() => setOpenEdit(true)}>
                  Edit Report
                </div>
              }

            </div>
            <div className={`px-2 py-6 mb-6 rounded-md`} style={{ backgroundColor: `${data.label.color}60` }}>
              <div className="flex items-center">
                <img
                  src={teeth}
                  className={`h-[90px] w-[90px] rounded-lg mr-2 `}
                />
                <div>
                  <div className="font-bold text-[20px]">{data.name}</div>
                  <div className={`text-[#243448] opacity-50`}>
                    {convertToIST(data.date_created)}
                  </div>
                  <div className="text-[#7A7A7A]">
                    Doctor Name &nbsp;&nbsp;
                    <span className="text-[#000000]">{doctorName}</span>
                  </div>
                  <div className="text-[#7A7A7A]">
                    Patient Name &nbsp;&nbsp;
                    <span className="text-[#000000]">{patientName}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[22px] font-bold mb-2">Description</div>
            <div className="opacity-50 mb-8">{data.description}</div>
            <div className="p-3 shadow-lg rounded-lg border border-gray-200 w-[60%] max-w-[100%] mb-4">
              <div className="font-bold mb-2 text-[18px]">
                Medical prescription
              </div>
              <div className="opacity-50">{data.medical_prescription}</div>
            </div>
            <div className="p-3 shadow-lg rounded-lg border border-gray-200 w-[60%] max-w-[100%] mb-8">
              <div className="font-bold mb-2 text-[18px]">Medical Charges</div>
              <div className="opacity-50 mb-4">{data.medical_charges}</div>
              <div className={`p-3 w-[60%] max-w-[100%] rounded-md`} style={{ backgroundColor: `${data.label.color}60` }}>
                {data.Bill && data.Bill.length > 0 && (
                  <>
                    {data.Bill.map((item, i) => {
                      return (
                        <div
                          className={`flex justify-between py-2  ${data.Bill.length !== i + 1 && "border-b border-gray-400"
                            }`}
                          key={i}
                        >
                          <div className="opacity-70">{item.item}</div>
                          <div className="font-bold">Rs. {item.price}</div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="text-[22px] font-bold mb-2">Attachments</div>
            <div className="flex items-center">
              {data.attachments &&
                data.attachments.map((item, i) => {
                  return (

                    <div onClick={() => {
                      notification("success", patientName ) // //////////////////////////////////////////////////////////////
                      setAttachemntPopup(!attachementPopup)
                      setAttachemntUrl(item.directus_files_id)
                    }} className="p-4 relative shadow-md border border-gray-300 rounded-md w-[150px] h-full mr-4">


                      <ArrowCircleDownRoundedIcon

                        sx={{
                          position: "absolute",
                          right: "5px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />

                      <center>
                        <FileOpenRoundedIcon
                          sx={{ fontSize: "3rem", marginBottom: "5px" }}
                        />
                        <div>Attachment {i + 1}</div>
                      </center>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>No such Report Available</>
        )}
        <EditReport state={OpenEdit} data={data} handleClose={closeEdit} reloadData={reloadParentData} />
      </div>
    </>
  );
}

export default Report;
