import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCd8HyWHOtO2UrzQmJKxZEoYWN2hkt00OQ",
  authDomain: "detist-93677.firebaseapp.com",
  projectId: "detist-93677",
  storageBucket: "detist-93677.appspot.com",
  messagingSenderId: "360752022478",
  appId: "1:360752022478:web:17d1731dcd812c6d8c219f",
  measurementId: "G-2JSVBJ3GD6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
navigator.serviceWorker.register("/firebase-message-sw.js").then(
  function (registration) {
    // Registration was successful
    console.log(
      "firebase-message-sw :ServiceWorker registration successful with scope: ",
      registration.scope
    );
    messaging.useServiceWorker(registration);
  },
  function (err) {
    // registration failed :(
    console.log(
      "firebase-message-sw: ServiceWorker registration failed: ",
      err
    );
  }
);

export default app;
// Initialize Firebase Cloud Messaging and get a reference to the service
