import React from 'react'
import Calender from "../../../aseets/adminNotificationsCalender.png";
function Notification() {
  return (
    <div className="bg-[#F4F5F9] rounded-xl p-[1.4rem] mb-4">
        <div className="flex">
          <img src={Calender} className="mr-3 h-[40px] w-[40px]" />
          <div>
            <div className="flex items-center mb-2">
              <div className="text-[14px] font-semibold mr-5">
                Appointment Booked
              </div>
              <div className="text-[#243448] opacity-50 text-sm">1 min ago</div>
            </div>
            <div className="text-[#7E8A98] text-[13px] xl:max-w-[80%] lg:max-w-[80%] md:max-w-[80%]">
              Bruce Wayne has book an appointment for dental checkup with Dr.
              Arvin Camp at 9:00 AM 23 April 2022
            </div>
          </div>
        </div>
      </div>
  )
}

export default Notification