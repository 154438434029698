import React, { useEffect, useState } from 'react'
import Dentist from "../aseets/dentistAppLogo.png"
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import axios from 'axios';
import teeth from "../aseets/teethImage.png"
const BaseUrl = process.env.REACT_APP_BASE_URL;
function ReportThumbnail(props) {
    const [data, setData] = useState("")
    useEffect(()=>{
        axios.get(`${BaseUrl}/items/label?filter[id][_eq]=${props.color}`).then(res=>{
            console.log(res.data)
            setData(res.data.data[0].color)
        })
    },[props.color])
    function convertToIST(utcTimeString) {
        // create a new Date object from the UTC time string
        var utcTime = new Date(utcTimeString);
      
        // create a new Date object, representing the same time in the IST time zone
        var ISTTime = new Date(utcTime.getTime() + (330*60*1000));
      
        return`${ISTTime.toDateString()}`;
      }
  return (
    <div className='p-2 flex items-center relative  rounded-xl w-fit min-w-[400px] max-w-full cursor-pointer' style={{backgroundColor:`${data}43`}}>
        <ChevronRightRoundedIcon className='absolute' sx={{fontSize:"2rem", color:data, top:"calc(50% - 1rem)", right:"10px"}} />
        <img src={teeth} className="h-[60px] w-[60px] object-cover rounded-md mr-2" />
        <div>
            <div style={{color:data}}>{props.name}</div>
            <div style={{color:data}}>{convertToIST(props.date)}</div>
        </div>
    </div>
  )
}

export default ReportThumbnail