import React from "react";
import Notification from "../../../components/admin/common/Notification";

function Notifications() {
  return (
    <div className="mt-6">
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
    </div>
  );
}
export default Notifications;
