import React from "react";

export default function FinanceButton({
  value,
  handleClick,
  isDisabled = false,
}) {
  return (
    <button
      onClick={handleClick}
      disabled={isDisabled}
      className={`${
        isDisabled ? "bg-[#2eb9aba9]" : "bg-[#2EB9AC]"
      } min-w-[160px] text-white px-4 py-2 text-md rounded-md disabled:cursor-not-allowed`}
    >
      {value}
    </button>
  );
}
