import style from "./style.module.css";
import { ReactComponent as AppointmentIcon } from "../../../../aseets/appointmentsIcon.svg";
import { ReactComponent as FilterIcon } from "../../../../aseets/filterIcon.svg";
import AppointmentCard from "../../../../components/AppointmentCard";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { ReactComponent as CrossIcon } from "../../../../aseets/crossIcon.svg";
import { ReactComponent as DownArrowIcon } from "../../../../aseets/downArrowIcon.svg";
import { ReactComponent as ClockIcon } from "../../../../aseets/clockIcon.svg";
import { ReactComponent as BackIcon } from "../../../../aseets/backIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../../aseets/calendarIcon.svg";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const BaseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};

const Dashboard = () => {
  let [status, setStatus] = useState("Pending");
  let [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
  let changeStatus = (sts) => {
    setStatus(sts);
  };
  useEffect(() => {
    axios
      .get(
        `${BaseUrl}/items/Appointment?fields=*.*&filter[doctor][_eq]=${localStorage.getItem(
          "userId"
        )}&filter[status][_eq]=${status}&sort[]=-date`
      )
      .then((res) => {
        console.log(res.data)
        setData(res.data.data);
      });
  }, [status]);
  let changeHandler = (e) => {
  };
  let reloadData1 = () =>{
    axios
    .get(
      `${BaseUrl}/items/Appointment?fields=*.*&filter[doctor][_eq]=${localStorage.getItem(
        "userId"
      )}&filter[status][_eq]=${status}&sort[]=-date`
    )
    .then((res) => {
      setData(res.data.data);
    });
  }
  return (
    <div>
      {/* filter  */}
      <ReactSlidingPane
        overlayClassName={style.overlayClass}
        isOpen={isOpen}
        width="450px"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setIsOpen(false);
        }}
        hideHeader={true}
      >
        <div className="w-[100%]">
          <div className="flex items-center justify-between mb-[2rem]">
            <div className="flex items-center">
              <button
                onClick={() => setIsOpen(false)}
                className="p-[1.2rem] bg-[#F4F5F9] rounded-[.8rem]"
              >
                <CrossIcon className="w-[1.1rem] h-[1.1rem] text-primary" />
              </button>
              <p className="font-medium text-[1.3rem] ml-[1rem]">Filter</p>
            </div>
            <button className="bg-primary text-white py-[.7rem] px-[2.5rem] rounded-[.5rem]">
              Ok
            </button>
          </div>
          <div className="mb-[2rem]">
            <p className="text-[1.35rem] mb-[.3rem] font-medium">Doctor Name</p>
            <div className="relative">
              <input className="border-[1px] p-[1rem] pr-[4rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black" />
              <div className="w-[2rem] h-[2rem] bg-primary flex justify-center items-center text-white rounded-[.5rem] absolute right-[1rem] top-[50%] translate-y-[-50%]">
                <DownArrowIcon />
              </div>
            </div>
          </div>
          <div className="mb-[2rem]">
            <p className="text-[1.35rem] mb-[.3rem] font-medium">Time</p>
            <div className="relative">
              <div className="w-[1.5rem] h-[1.5rem] flex justify-center items-center text-[#C2C7D1] rounded-[.5rem] absolute left-[1rem] top-[50%] translate-y-[-50%]">
                <ClockIcon />
              </div>
              <input className="border-[1px] p-[1rem] pl-[4rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black" />
            </div>
          </div>
        </div>
      </ReactSlidingPane>
      {/* reschedule  */}
      {isRescheduleOpen && (
        <div className="w-[100%] h-[100%] bg-[#d9d9d980] fixed top-0 left-0 flex justify-center items-center">
          <div
            onClick={() => setIsRescheduleOpen(false)}
            className="absolute top-0 left-0 w-[100%] h-[100%]"
          ></div>

          <div className="w-[70%] max-w-[650px] z-10 bg-white overflow-hidden rounded-[1rem]">
            <div className="bg-primary h-[3rem] flex justify-center items-center relative">
              <BackIcon className="text-[white] w-[1rem] h-[1rem] cursor-pointer absolute left-[1rem]" />
              <p className="text-[white] font-medium">Reschedule</p>
            </div>
            <div className="p-[1rem]">
              <div className="px-[1rem] pb-[1rem] border-b-2 border-b-[#00000011] flex gap-[2rem]">
                <div className="w-[100%]">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      inputFormat="dd/MM/yyyy"
                      value={"12/31/2022"}
                      onChange={changeHandler}
                      disablePast
                      // maxDate={eventEndDate && eventEndDate}
                      // onChange={(e) => setEventStartDate(e)}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F4F5F9",
                            borderRadius: ".5rem",
                            position: "relative",
                            paddingLeft: "1rem",
                          }}
                        >
                          <input
                            ref={inputRef}
                            {...inputProps}
                            style={{ cursor: "pointer" }}
                            className={
                              "bg-[#F4F5F9] outline-none w-[100%] h-[3rem] rounded-[.5rem]"
                            }
                            placeholder={"Select"}
                          />
                          <CalendarIcon className="absolute right-[1rem] w-[1.5rem] h-[1.5rem] text-[#A8AFBD]" />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                {/* <div className="w-[50%]">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileTimePicker
                      label="Time"
                      inputFormat="dd/MM/yyyy"
                      value={new Date()}
                      disablePast
                      // maxDate={eventEndDate && eventEndDate}
                      // onChange={(e) => setEventStartDate(e)}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F4F5F9",
                            borderRadius: ".5rem",
                            position: "relative",
                            paddingLeft: "1rem",
                          }}
                        >
                          <p
                            ref={inputRef}
                            {...inputProps}
                            className="h-[3rem] w-[100%] flex items-center cursor-pointer"
                          >
                            05:33 AM
                          </p>
                          <ClockIcon className="absolute right-[1rem] w-[1.5rem] h-[1.5rem] text-[#A8AFBD]" />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div> */}
              </div>
              <div className="mt-[1rem]">
                <p className="text-[1.2rem]">Choose your time</p>
                <div className="flex gap-[1.5rem] flex-wrap mt-[1rem]">
                  <p className="bg-[#F4F5F9] text-[#A7ACB8] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#fff] border-[1px] border-[#E1EEF2] text-[#243448] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#243448] border-[1px] border-[#243448] text-[#fff] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#fff] border-[1px] border-[#E1EEF2] text-[#243448] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#243448] border-[1px] border-[#243448] text-[#fff] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#F4F5F9] text-[#A7ACB8] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#fff] border-[1px] border-[#E1EEF2] text-[#243448] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#243448] border-[1px] border-[#243448] text-[#fff] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#fff] border-[1px] border-[#E1EEF2] text-[#243448] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                  <p className="bg-[#243448] border-[1px] border-[#243448] text-[#fff] p-[1rem] py-[.7rem] rounded-[.5rem]">
                    10:00 AM
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between gap-[3rem]">
        <div
          className={`${
            status == "Pending"
              ? `bg-[orange] text-black border-[3px] border-greyBorder`
              : `${style.activeAppointmentCard}`
          } flex-1 cursor-pointer  px-[1.3rem] py-[1.7rem] rounded-[1rem] flex items-center`}
          onClick={() => changeStatus("Pending")}
        >
          <div className="w-[4rem] h-[4rem] bg-white rounded-[.7rem] flex justify-center items-center">
            <AppointmentIcon className="w-[80%] h-[80%] text-primary" />
          </div>
          <p className=" ml-[.9rem] text-[1.1rem]">Upcoming Appointments</p>
        </div>

        <div
          className={`${
            status == "Completed"
              ? `bg-[green] text-primary border-[3px] border-greyBorder`
              : `${style.activeAppointmentCard}`
          } flex-1 cursor-pointer  px-[1.3rem] py-[1.7rem] rounded-[1rem] flex items-center`}
          onClick={() => changeStatus("Completed")}
        >
          <div className="w-[4rem] h-[4rem] bg-white rounded-[.7rem] flex justify-center items-center">
            <AppointmentIcon className="w-[80%] h-[80%] text-primary" />
          </div>
          <p className="text-black font-medium ml-[.9rem] text-[1.1rem]">
            Completed Appointments
          </p>
        </div>

        <div
          className={`${
            status == "Cancelled"
              ? `bg-[red] text-primary border-[3px] border-greyBorder`
              : `${style.activeAppointmentCard}`
          } flex-1 cursor-pointer  px-[1.3rem] py-[1.7rem] rounded-[1rem] flex items-center`}
          onClick={() => changeStatus("Cancelled")}
        >
          <div className="w-[4rem] h-[4rem] bg-white rounded-[.7rem] flex justify-center items-center">
            <AppointmentIcon className="w-[80%] h-[80%] text-primary" />
          </div>
          <p className="text-black font-medium ml-[.9rem] text-[1.1rem]">
            Cancelled Appointments
          </p>
        </div>
        
      </div>
      
      <div className="flex items-center my-[1rem] justify-between">
        <p className="font-bold text-[1.5rem]">{status} Appointments</p>
        {/* <button
          onClick={() => setIsOpen(true)}
          className="w-[3.5rem] h-[3.5rem] bg-primary flex justify-center items-center rounded-[1rem]"
        >
          <FilterIcon className="w-[1.5rem] h-[1.5rem] text-white" />
        </button> */}
      </div>
      {data.length == 0 && (
        <div className="font-bold text-[1.5rem] text-primary text-center">
          No Appointments to be shown
        </div>
      )}

      <div className="flex justify-start gap-[2rem] flex-wrap">
        {data.map((item, i) => {
          return (
            <AppointmentCard
              data={item}
              onReschedulePress={() => setIsRescheduleOpen(true)}
              key={i}
              reloadData={reloadData1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
