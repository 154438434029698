import LoginBanner from "../../../aseets/loginBanner.png";
import app from "../../../firebase";
import { ReactComponent as LoginLockIcon } from "../../../aseets/loginLockIcon.svg";
import { ReactComponent as LoginUserIcon } from "../../../aseets/loginUserIcon.svg";
import { useEffect, useState } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "firebase/auth";
import axios from "axios";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useNavigate } from "react-router-dom";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const auth = getAuth(app);
const Login = () => {
  let [number, setNumber] = useState("");
  let [otp, setOtp] = useState("");
  let [step2, setStep2] = useState(false);
  let [email, setEmail] = useState("");
  const navigate = useNavigate();
  let handleClick = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {
          document.getElementById("recaptcha-container").style.display = "none";
          setStep2(true);
        },
        "expired-callback": () => {
          console.log("error");
        },
      },
      auth
    );
    const phoneNumber = "+91" + String(number);
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let something = () => {
    const code = String(otp);
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        const user = result.user;

        onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            // console.log(uid);
            axios
              .get(`${BaseUrl}/users?filter[contact_number][_eq]=91${number}`)
              .then(async (res) => {
                // console.log(res);
                if (res.data.data[0].email) {
                  setEmail(res.data.data[0].email);
                  const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      email: res?.data?.data[0]?.email,
                      password: uid,
                    }),
                  };
                  await fetch(`${BaseUrl}/auth/login`, requestOptions).then(
                    async (result) => {
                      // console.log(result);
                      let data = await result.json();
                      // console.log("kjdjkhjshj", data);
                      // localStorage.clear();
                      
                      localStorage.setItem(
                        "access_token",
                        data?.data?.access_token
                      );
                      localStorage.setItem(
                        "refresh_token",
                        data?.data?.refresh_token
                      );
                      localStorage.setItem("userId", res.data.data[0].id);
                      localStorage.setItem("role", res.data.data[0].role);
                      localStorage.setItem(
                        "user",
                        JSON.stringify(res.data.data[0])
                      );
                      if (
                        res.data.data[0].role ==
                        "e2709430-b9bc-4945-893b-e8f9b223f63a"
                      ) {
                        navigate("/");
                      } else if (
                        res.data.data[0].role ==
                        "6e244d53-82c5-4aa2-8d90-9e737f8da3ca"
                      ) {
                        navigate("/admin");
                      } else {
                        document.getElementById("otp_error").innerHTML =
                          "Please login if you are a registered Doctor or front desk";
                        document
                          .getElementById("otp_error")
                          .classList.remove("hidden");
                      }
                    }
                  );
                } else {
                  // alert("No Creds available");
                }
              });
          } else {
          }
        });
      })
      .catch((error) => {
        document.getElementById("otp_error").innerHTML = "Invalid OTP";
        document.getElementById("otp_error").classList.remove("hidden");
      });
  };
  // const setOtpChange = (e) => {
  //   setOtp(e.target.value);
  //   if (!document.getElementById("otp_error").classList.contains("hidden")) {
  //     document.getElementById("otp_error").classList.add("hidden");
  //   }
  // };
  const handleChange = (newValue) => {
    setOtp(newValue)
    if (!document.getElementById("otp_error").classList.contains("hidden")) {
         document.getElementById("otp_error").classList.add("hidden");
         }
  }
  let resendOtp = () => {
    const phoneNumber = "+91" + String(number);
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="fixed w-[100%] h-[100%] flex">
      <div className="w-[50%] flex justify-center items-center">
        <div className="max-w-[400px] w-[100%] max-h-[100vh] overflow-y-auto mx-auto flex flex-col justify-center items-center py-[1rem]">
          <div className="flex flex-col justify-center items-center">
            <div className="w-[4rem] h-[4rem] bg-primary rounded-[.5rem] text-white flex justify-center items-center">
              <p className="text-[3rem] font-semibold">D</p>
            </div>
            <p className="text-primary mt-[.5rem] font-semibold">Smilify</p>
          </div>
          <div className="flex flex-col justify-center items-center mt-[1rem]">
            <p className="text-[1.1rem] font-semibold">Welcome</p>
            <p className="text-[#909090]">
              Please Login to countinue to Smilify
            </p>
          </div>
          <div className="w-[100%] mt-[1rem]">
            <p className="text-[#D0D0D0]">Phone Number</p>
            <div className="w-[100%] h-[3rem] mt-[.5rem] border-[1px] border-[#A0A3BD] items-center rounded-[.5rem] flex">
              <LoginUserIcon className="w-[1.5rem] h-[1.5rem] mx-[1rem] overflow-hidden object-contain text-primary" />
              <input
                type="number"
                className="h-[100%] w-[85%] outline-none pr-[1rem]"
                id="number"
                value={number}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 10) {
                    setNumber(inputValue);
                  }
                }}
                placeholder="0000000000"
                disabled={step2}
              />
            </div>
            <div
              className="text-[#dd6b6b] mt-1 float-right hidden"
              id="email_error"
            >
              Required
            </div>
          </div>
          <div className="w-[100%] mt-[1rem]">
            {!step2 && (
              <button
                className="w-[100%] bg-primary py-[.8rem] text-white rounded-[.5rem] capitalize"
                onClick={handleClick}
              >
                Verify OTP
              </button>
            )}
          </div>
          <div id="recaptcha-container" className="my-[0.5rem]"></div>
          {step2 && (
            <>
              <div className="w-[100%] mt-[1rem]">
                <p className="text-[#D0D0D0]">One-Time-Password (OTP)</p>
                <div className="w-[100%] h-[3rem] mt-[.5rem]   items-center rounded-[.5rem] flex">
                  {/* <LoginLockIcon className="w-[1.5rem] h-[1.5rem] mx-[1rem] overflow-hidden object-contain text-primary" />
                  <input
                    type={`number`}
                    className="h-[100%] w-[76%] pr-[1rem] outline-none"
                    value={otp}
                    onChange={setOtpChange}
                  /> */}
                  <MuiOtpInput type={"number"} className="h-[100%] p-0" length={6} value={otp} onChange={handleChange} />
                </div>
                <div
                  className="float-right mt-3 text-primary cursor-pointer"
                  onClick={resendOtp}
                >
                  Resend OTP
                </div>
                <div className="text-[#dd6b6b] mt-1 hidden" id="otp_error">
                  Required
                </div>
              </div>
              <div className="w-[100%] mt-[1rem]">
                <button
                  className="w-[100%] bg-primary py-[.8rem] text-white rounded-[.5rem] capitalize"
                  onClick={something}
                >
                  Log in
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="w-[50%] h-[100%] bg-primary flex justify-center items-center">
        <img
          className="w-[80%] h-[80%] object-contain max-w-[40rem] max-h-[40rem]"
          src={LoginBanner}
          alt="..."
        />
      </div>
    </div>
  );
};

export default Login;
