import DentistLogo from "../../aseets/dentistAppLogo.png";
import DummyUser from "../../aseets/logo.png";
import ActiveHomeIcon from "../../aseets/activeHomeIcon.svg";
import HomeIcon from "../../aseets/homeIcon.svg";
import PrivacyPolicyIcon from "../../aseets/privacyPolicyIcon.svg";
import ActivePrivacyPolicyIcon from "../../aseets/activePrivacyPolicyIcon.svg";
import FinanceReportIcon from "../../aseets/financeReport.svg";
import ActiveFinanceReportIcon from "../../aseets/activeFinanceReport.svg";
import LogoutIcon from "../../aseets/logoutIcon.svg";
import AppointmentIcon from "../../aseets/appointment.svg";
import ActiveAppointmentIcon from "../../aseets/activeAppointmentsIcon.svg";
import { ReactComponent as CalendarIcon } from "../../aseets/calendarIcon.svg";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const AdminSidebar = () => {
  let navigate = useNavigate();
  let [data, setData] = useState({});
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setData(JSON.parse(localStorage.getItem("user")));
    }
    console.log(window.location.href);
  }, []);
  let logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  let navMe = (item) => {
    navigate(item);
  };
  return (
    <div
      className={`scrollbar-thumb-primary scrollbar-thumb-rounded-xl scrollbar-track-white scrollbar-thin w-[17rem] h-[100vh] overflow-y-auto fixed left-0 top-0 py-[5vh] pl-[2rem] text-white bg-primary`}
      style={{ zIndex: "999" }}
    >
      <div className="min-h-[90%]">
        {/* <div className="w-[100%] pr-[2rem] flex flex-col justify-center items-center">
          <img
            className="w-[4rem] h-[4rem] rounded-[.7rem]"
            src={DentistLogo}
            alt="..."
          />
          
          <h2 className="text-[1.7rem] mt-[.5rem] font-semibold">
            Dentist App
          </h2>
        </div> */}
        <div className="flex flex-col justify-center items-center pr-[2rem] mt-[0rem]">
          <div className="flex justify-center items-center h-[4rem] w-[10rem] rounded-[50%] relative">
            {/* <img className="h-[100%] w-[100%]" src={ProfilRing} alt="..." /> */}
            <img
              className="w-[100%] h-[100%] object-contain absolute"
              src={data.avatar ? `${BaseUrl}/assets/${data.avatar}` : DummyUser}
              alt="..."
            />
            {/* <h2 className="text-[1.7rem] mt-[.5rem] font-semibold text-white">Smilify</h2> */}
          </div>
          <h2 className="text-[1.7rem] mt-[.7rem] font-semibold text-white">
          Smilify
          </h2>
          <p className="text-[1.1rem] mt-[.5rem] font-semibold">
            {data.first_name} {data.last_name}
          </p>
        </div>

        

        <div className="flex flex-col mt-[1rem]">
          <div
            className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
              window.location.href.endsWith("admin") ? "bg-white" : "bg-primary"
            }  cursor-pointer`}
            onClick={() => navMe("/admin")}
          >
            {window.location.href.endsWith("admin") ? (
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={ActiveHomeIcon}
                alt="..."
              />
            ) : (
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
                src={HomeIcon}
                alt="..."
              />
            )}
            <p
              className={`${
                !window.location.href.endsWith("admin")
                  ? "text-white"
                  : "text-primary"
              } mt-[.2rem] font-medium`}
            >
              Home
            </p>
          </div>
          
          <div
            className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
              window.location.href.includes("appointments")
                ? "bg-white"
                : "bg-primary"
            } cursor-pointer`}
            onClick={() => navMe("/admin/appointments")}
          >
            {window.location.href.includes("appointments") ? (
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem] "
                src={ActiveAppointmentIcon}
                alt="..."
              />
            ) : (
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem] "
                src={AppointmentIcon}
                alt="..."
              />
            )}
            <p
              className={`${
                !window.location.href.includes("appointments")
                  ? "text-white"
                  : "text-primary"
              } mt-[.2rem] font-medium`}
            >
              Appointments
            </p>
          </div>
          
          {/* //  Finance Report */}
          <div
            className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
              window.location.href.includes("finance-report")
                ? "bg-white"
                : "bg-primary"
            } cursor-pointer`}
            onClick={() => navMe("/admin/finance-report")}
          >
             {window.location.href.includes("finance-report") ? (
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem] "
                src={ActiveFinanceReportIcon}
                alt="..."
              />
            ) : (
              <img
              className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
              src={FinanceReportIcon}
              alt="..."
            />
            )}
            <p
              className={`${
                !window.location.href.includes("finance-report")
                  ? "text-white"
                  : "text-primary"
              } mt-[.2rem] font-medium`}
            >
              Finance Report
            </p>
          </div>

          {/* <div
            className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
              window.location.href.includes("privacy-policy")
                ? "bg-white"
                : "bg-primary"
            } cursor-pointer`}
            onClick={() => navMe("/admin/privacy-policy")}
          >
              {window.location.href.includes("privacy-policy") ? (
              <img
                className="w-[1.6rem] h-[1.6rem] mr-[1rem] "
                src={ActivePrivacyPolicyIcon}
                alt="..."
              />
            ) : (
              <img
              className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
              src={PrivacyPolicyIcon}
              alt="..."
            />
            )}
           
            <p
              className={`${
                !window.location.href.includes("privacy-policy")
                  ? "text-white"
                  : "text-primary"
              } mt-[.2rem] font-medium`}
            >
              Privacy Policy
            </p>
          </div> */}

          <div
            className={`py-[.5rem] mb-[.5rem] px-[1rem] flex items-center rounded-l-[2rem] ${
              window.location.href.includes("calendar")
                ? "bg-white"
                : "bg-primary"
            } cursor-pointer`}
            onClick={() => navMe("/admin/calendar")}
          >
            <CalendarIcon
              className={`${
                !window.location.href.includes("calendar")
                  ? "text-white"
                  : "text-primary"
              } w-[1.6rem] h-[1.6rem] mr-[1rem]`}
            />

            <p
              className={`${
                !window.location.href.includes("calendar")
                  ? "text-white"
                  : "text-primary"
              } mt-[.2rem] font-medium`}
            >
              Calendar
            </p>
          </div>

        </div>
      </div>
      <div className="min-h-[10%] flex items-center pl-[1.3rem] pr-[2rem]">
        <img
          className="w-[1.6rem] h-[1.6rem] mr-[1rem]"
          src={LogoutIcon}
          alt="..."
        />
        <p className="text-white font-medium cursor-pointer" onClick={logout}>
          Log Out
        </p>
      </div>
    </div>
  );
};

export default AdminSidebar;
