import { Grid } from "@mui/material";
import React, { useState } from "react";
import Filter from "../../../aseets/adminNotificationsFilter.png";
import PaymentBox from "../../../components/admin/common/PaymentBox";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "0px",
  right: "0px",
  width: 400,
  height: "100%",
  bgcolor: "white",
  border: "none",
  boxShadow: 24,

  // p: 1,
};
function Payment() {
    let [filtersOpen, setFiltersOpen] = useState(false)
  return (
    <div className="mt-[1rem]">
      <div className="flex justify-between mb-6">
        <h1 className="font-semibold text-[20px]">Payment History</h1>
        <div className="flex">
          <button className="text-[20px] px-[1rem] bg-[#F4F5F9] rounded-lg flex items-center justify-center mr-5">
            Show All
          </button>
          <img src={Filter} className="h-[40px] cursor-pointer" />
        </div>
      </div>
      <div className="w-[100%]">
        <div className="min-w-[1000px] overflow-x-auto">
          <div className="bg-[#F4F5F9] px-[25px] py-[15px] rounded-xl mb-4">
            <Grid container spacing={1}>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#2EB9AC] font-[20px]"> User ID</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#2EB9AC] font-[20px]"> User Name</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#2EB9AC] font-[20px]"> Doctor Name</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#2EB9AC] font-[20px]">Payment Status</div>
              </Grid>
              <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <div className="text-[#2EB9AC] font-[20px]">
                  Download Receipt
                </div>
              </Grid>
            </Grid>
          </div>
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
          <PaymentBox />
        </div>
      </div>
      {/* <Modal
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={filtersOpen}>
          <Box sx={style} className={"focusRemove"}>
            <div className={css.sidePopupDiv}>
              
            </div>
          </Box>
        </Fade>
      </Modal> */}
    </div>
  );
}

export default Payment;
