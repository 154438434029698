import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { SendOutlined } from '@mui/icons-material';
const BaseUrl = process.env.REACT_APP_BASE_URL;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius:"10px",
  bgcolor: '#fff',
  boxShadow: 24,
  p: 2,
};

export default function BasicModal(props) {
    let [data, setData] = React.useState([])
    React.useEffect(()=>{
        axios.get(`${BaseUrl}/items/label`).then(res=>{
            console.log(res.data.data)
            setData(res.data.data)
        })
    },[])
    const sendID = (id)=>{
        props.getLabel(id)
    }
  return (
    <div>
      <Modal
        open={props.state}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="focus-visible:outline-none">
            <div className='text-[18px] font-bold mb-2'>Color Tags</div>
            <div className='text-[16px] text-[#7E8A98] pb-2 mb-4 border-b-2 border-gray-200'>Lorem ipsum dolor sit amet, consectetur adipiscing </div>
            {
                data.map((item,i)=>{
                    return(
                        <div className={`flex items-center px-4 py-2 bg-[${item.color}43] rounded-md mb-4 cursor-pointer`}  style={{backgroundColor:`${item.color}43`}} onClick={()=>sendID(item)}>
                        <div className={`h-[15px] w-[15px] rounded-full bg-[${item.color}] mr-3`} style={{backgroundColor:item.color}}></div>
                        <div className={`text-[${item.color}]`}  style={{color:item.color}}>{item.name}</div>
                    </div>
                    )
                })
            }
          
         </Box>
      </Modal>
    </div>
  );
}