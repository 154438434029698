import React ,{useState, useEffect}from 'react'
import DummyUser from "../aseets/patientAvatar.jpg";
import { ReactComponent as CrossIcon } from "../aseets/crossIcon.svg";
import { ReactComponent as PlusIcon } from "../aseets/plusIcon.svg";
import { ReactComponent as DocumentUploadIcon } from "../aseets/documentUploadIcon.svg";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import EditIcon from "@mui/icons-material/Edit";
import style from "./style.module.css";
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import ColorTags from "./ColorTags"
const BaseUrl = process.env.REACT_APP_BASE_URL;
let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  },
};
function EditReport(props) {
    let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(()=>{
if(props.state === true){
    setIsOpen(true) 
}else{
    setIsOpen(false)
}
  },[props.state])
  let [reportData, setReportData] = useState({
    name: "",
    description: "",
    prescription: "",
    medicalCharges: "",
    label: "",
  });
  let [uploadedAttachment, setUploadedAttachment] = useState([])
  useEffect(()=>{
    console.log(props.data)
    setReportData({
        ...reportData,
        name:props.data.name,
        description: props.data.description,
        prescription: props.data.medical_prescription,
        medicalCharges: props.data.medical_charges,
        label: props.data.label ? props.data.label : ""

    })
    setRates(
        props.data.Bill ? props.data.Bill : []
    )
    if(props.data.attachments && props.data.attachments.length > 0){
        let arr = []
        for(let i=0; i< props.data.attachments.length ;i++ ){
            let data = {}
            data.directus_files_id = props.data.attachments[i].directus_files_id
            arr.push(data)
        }
        console.log(arr)
        setUploadedAttachment(arr)
    }
   
    
  },[props.data])
  let param = useParams();
  let getTime = (time = "15:00") => {
    let smallTime = time.slice(0, 2);
    if (smallTime <= 12) {
      return `${smallTime}:${time.slice(3, 5)} AM`;
    } else {
      smallTime = smallTime - 12;
      return `${smallTime < 10 && "0"}${smallTime}:${time.slice(3, 5)} PM`;
    }
  };

  let changeHandler = (e) => {
    setReportData({
      ...reportData,
      [e.target.id]: e.target.value,
    });
  };
  let [diagrams, setDiagrms] = useState([]);

  let removeDoc = (i) => {
    let arr = diagrams.filter(function (item) {
      return item !== i;
    });
    setDiagrms([...arr]);
  };
  let [attachments, setAttachments] = useState([]);
  let addAttachment = (e) => {
    if (e.target.files[0]) {
      setAttachments([...attachments, e.target.files[0]]);
    }
  };
  let removeAtt = (i) => {
    let arr = attachments.filter(function (item) {
      return item !== i;
    });
    setAttachments([...arr]);
  };
  let removeUploaded = (i) =>{
    let arr = uploadedAttachment.filter(function (item) {
        return item !== i;
      });
      setUploadedAttachment([...arr]);
  }
  let [payment, setPayment] = useState({
    item: "",
    price: "",
  });
  let rateHandler = (e) => {
    setPayment({
      ...payment,
      [e.target.id]: e.target.value,
    });
  };
  let [rates, setRates] = useState([]);
  let addRate = () => {
    if (payment.item !== "" && payment.price !== "") {
      let temp = {
        item: payment.item,
        price: payment.price,
      };
      setRates([...rates, temp]);
      setPayment({
        item: "",
        price: "",
      });
    }
  };
  let removeRate = (i) => {
    let arr = rates.filter(function (item) {
      return item !== i;
    });
    setRates([...arr]);
  };
  const [tagsModel, setTagsModel] = useState(false);
  const tagsModelClose = () => {
    setTagsModel(false);
  };
  const changeLabel = (id) => {
    setReportData({
      ...reportData,
      label: id,
    });
    setTagsModel(false);
  };
  let uploadReport = () => {
    let arr1 = [];
    if (attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        const formData = new FormData();
        formData.append("title", attachments[i].name);
        formData.append("file", attachments[i]);
        axios.post(`${BaseUrl}/files`, formData, config).then((res) => {
          console.log(res);
          let data1 = {};
          data1.directus_files_id = res.data.data.id;
          arr1.push(data1);
        });
      }
    }
    
    setTimeout(() => {
        let arr2;
        if(uploadedAttachment.length > 0){
             arr2 = arr1.concat(uploadedAttachment)
        }else{
            arr2 = arr1
        }
 
    let body = {
      name: reportData.name,
      description: reportData.description,
      medical_prescription: reportData.prescription,
      medical_charges: reportData.medicalCharges,
      attachments: arr2,
      Bill: rates,
      label: reportData.label.id,
    };
    console.log(body);
      axios.patch(`${BaseUrl}/items/Reports/${props.data.id}`, body, config).then((res) => {
        console.log(res);
        //push
        props.reloadData()
      });
    }, 1000);
  };
  let sendPage = (id) =>{
    navigate(`/report/${id}`)
  }
  return (
    <div>
        <ReactSlidingPane
            overlayClassName={style.overlayClass}
            isOpen={props.state}
            width="600px"
            onRequestClose={props.handleClose}
            hideHeader={true}
          >
            <div className="w-[100%]">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <button
                    onClick={props.handleClose}
                    className="p-[1.2rem] bg-[#F4F5F9] rounded-[.8rem]"
                  >
                    <CrossIcon className="w-[1.1rem] h-[1.1rem] text-primary" />
                  </button>
                  <p className="font-medium text-[1.3rem] ml-[1rem]">
                    Edit Report
                  </p>
                </div>
                <button
                  className="bg-primary text-white py-[.7rem] px-[2.5rem] rounded-[.5rem]"
                  onClick={uploadReport}
                >
                  Save
                </button>
              </div>
              <div className="mb-[2rem] mt-4">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Test Name
                </p>
                <input
                  className="border-[1px] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
                  placeholder="Write down the description"
                  value={reportData.name}
                  onChange={changeHandler}
                  id="name"
                />
              </div>
              {reportData.label !== "" && reportData.label.color ? (
                <div
                  className={`flex items-center relative px-4 py-2 rounded-md mb-4`}
                  style={{ backgroundColor: `${reportData.label.color}43` }}
                >
                  <EditIcon
                    sx={{
                      color: reportData.label.color,
                      position: "absolute",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setTagsModel(true)}
                  />
                  <div
                    className={`h-[15px] w-[15px] rounded-full mr-3`}
                    style={{
                      backgroundColor:
                        reportData.label && reportData.label.color,
                    }}
                  ></div>
                  <div
                    style={{
                      color: reportData.label && reportData.label.color,
                    }}
                  >
                    {reportData.label && reportData.label.name}
                  </div>
                </div>
              ) : (
                <label
                  className="flex items-center justify-center text-[#979797] mt-[1rem] w-[100%] cursor-pointer"
                  onClick={() => setTagsModel(true)}
                >
                  <PlusIcon className="w-[2rem] h-[2rem]" />
                  <p className="text-[1.1rem] ml-[1rem]">Add Color Tags</p>
                </label>
              )}

              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Description
                </p>
                <textarea
                  className="border-[1px] h-[8rem] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
                  placeholder="Write down the description"
                  value={reportData.description}
                  onChange={changeHandler}
                  id="description"
                />
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Medical prescription
                </p>
                <textarea
                  className="border-[1px] h-[8rem] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black"
                  placeholder="Write down the description"
                  value={reportData.prescription}
                  onChange={changeHandler}
                  id="prescription"
                />
                {diagrams.map((item, i) => {
                  return (
                    <>
                      <div className="my-1 ">
                        <div
                          className="text-[red] float-right cursor-pointer"
                          onClick={() => removeDoc(item)}
                        >
                          X
                        </div>
                        <div>{item.name}</div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Medical Charges
                </p>
                <div className="border-[1px] p-[1rem] border-secondaryTextField w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none focus:border-black">
                  <textarea
                    className=" p-[0rem] w-[100%] rounded-[.7rem] placeholder:text-[#000] placeholder:opacity-[.2] outline-none"
                    placeholder="Write down the description"
                    value={reportData.medicalCharges}
                    onChange={changeHandler}
                    id="medicalCharges"
                  />
                  <div className="bg-[#F9F9F9] p-[1rem] pt-[.5rem] mt-[1rem] rounded-[.5rem] w-[100%] ">
                    <div className="border-b-[1px] border-b-secondaryTextField">
                      <div className=" flex justify-between my-[.5rem]">
                        <input
                          className="w-[90%] bg-[#F9F9F9] text-[1.1rem] p-1"
                          placeholder="Add Detail"
                          value={payment.item}
                          id="item"
                          onChange={rateHandler}
                        />

                        <p className="text-black text-[1.1rem] font-medium">
                          Rs.{" "}
                          <input
                            className="w-[80%] bg-[#F9F9F9] text-[1.1rem] p-1"
                            placeholder="Add price"
                            type="number"
                            id="price"
                            value={payment.price}
                            onChange={rateHandler}
                          />
                        </p>
                      </div>
                    </div>
                    <button
                      className="flex items-center justify-center text-[#979797] mt-[1rem] w-[100%]"
                      onClick={addRate}
                    >
                      <PlusIcon className="w-[2rem] h-[2rem]" />
                      <p className="text-[1.1rem] ml-[1rem]">Add</p>
                    </button>
                    {rates.map((item, i) => {
                      return (
                        <>
                          <div
                            className="float-right mt-1 ml-3 text-[red] cursor-pointer"
                            onClick={() => removeRate(item)}
                          >
                            X
                          </div>
                          <div className="flex justify-between items-center p-1">
                            <div>{item.item}</div>
                            <div>Rs. {item.price}</div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mb-[2rem]">
                <p className="text-[1.35rem] mb-[.3rem] font-medium">
                  Attachment
                </p>

                <label
                  htmlFor="attachment"
                  className="cursor-pointer flex items-center border-[3px] border-[#CBD3F1] border-dashed py-[1rem] rounded-[1rem] justify-center text-[#979797] mt-[1rem] w-[100%]"
                >
                  <DocumentUploadIcon className="w-[2rem] h-[2rem] text-primary" />
                  <p className="text-[1.1rem] ml-[1rem]">Browse file to add</p>
                </label>
                <input
                  className="hidden"
                  type="file"
                  id="attachment"
                  onChange={addAttachment}
                />
                {uploadedAttachment.map((item, i) => {
                  return (
                    <>
                      <div className="my-1 ">
                        <div
                          className="text-[red] float-right cursor-pointer"
                          onClick={() => removeUploaded(item)}
                        >
                          X
                        </div>
                        <div>Attachment {i+1}</div>
                      </div>
                    </>
                  );
                })}
                {attachments.map((item, i) => {
                  return (
                    <>
                      <div className="my-1 ">
                        <div
                          className="text-[red] float-right cursor-pointer"
                          onClick={() => removeAtt(item)}
                        >
                          X
                        </div>
                        <div>{item.name}</div>
                      </div>
                    </>
                  );
                })}
                
              </div>
            </div>
          </ReactSlidingPane>
          <ColorTags
        state={tagsModel}
        handleClose={tagsModelClose}
        getLabel={changeLabel}
      />
    </div>
  )
}

export default EditReport