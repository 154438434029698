import { Route, Routes } from "react-router";
import Navbar from "../../../components/Navbar";
import Sidebar from "../../../components/Sidebar";
import Dashboard from "./Dashboard";
import DoctorProfile from "./DoctorProfile";
import Payment from "./Payment";
import UserProfile from "./UserProfile";
import Report from "../../Report";
const ProtectedScreens = () => {
  return (
    <div>
      <Sidebar />
      <div className="w-[100%] pl-[19rem] pr-[2rem] relative">
        <div className="w-[100%] mx-auto max-w-[1300px] pb-[2rem]">
          <Navbar />
          <div className="pt-[1rem]">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/appointment/:id" element={<UserProfile />} />
              <Route path="/doctor-profile" element={<DoctorProfile />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/report/:id" element={<Report />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectedScreens;
