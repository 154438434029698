import React from 'react'

export default function TableHeader() {
  return (
    <tr className='text-md'>
        <th className="border border-black px-2 py-2">Date</th>
        <th className="border border-black px-2 py-2">Patient Name</th>
        <th className="border border-black px-2 py-2">Treatment Status</th>
        <th className="border border-black px-2 py-2">Transaction Details</th>
        <th className="border border-black px-2 py-2">Payment Mode</th>
        <th className="border border-black px-2 py-2">Paid Amount</th>
    </tr>
  )
}
